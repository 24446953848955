// General Imports
import React from 'react'
import ReactToPrint from 'react-to-print'
import PropTypes from 'prop-types'
import { pathname, useMuiRegister } from '../lib/misc'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from '../lib/axios.js'

// Material UI (MUI) Imports
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'

// Custom Components
import Loading from './Loading'
import ClinicianInformation from './ClinicianInformation'
import PatientInformation from './PatientInformation'
import DiseaseInformation from './DiseaseInformation'
import TestResultsInformation from './TestResultsInformation'

// Context Imports
import UserContext from '../lib/context/UserContext'
import SourcesContext from '../lib/context/SourcesContext'
import SnackbarContext from '../lib/context/SnackbarContext'

// Validation Imports
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import makeStyles from '@mui/styles/makeStyles'
import moment from 'moment'
import PatientFilter from './PatientFilter'
import PatientSearchResults from './PatientSearchResults'
import { useSelector } from 'react-redux'

// CSS Styles
const useStyles = makeStyles({
  topPrintBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  '@media print': {
    topPrintBar: {
      display: 'none'
    }
  },
  submitNote: {
    textAlign: 'center',
    fontFamily: 'Roboto,sans-serif',
    color: 'rgba(0,0,0,.87)'
  }
})

// For a proper display of an existing case phone number
const formatPhone = (area, num) => {
  if(area && num)
    return '(' + area + ') ' + num.slice(0, 3) + '-' + num.slice(3, 7)
}

// Yup Validation Schema
let specimenDate = null
const formSchema = yup.object().shape({
  facility_id: yup.number().transform((value, originalvalue) => {
    if (originalvalue !== null) {
      value = originalvalue.id
      return value
    }
  }).required('Required'),
  facility: yup.object().shape({
    address: yup.object().shape({
      street: yup.string().nullable(),
      city: yup.string().nullable(),
      zip: yup.string().nullable().matches(/^[0-9]{5}(-[0-9]{4})?$/, 'Zip code must be ##### or #####-#### format'),
      unit: yup.string().nullable().default(''),
      county_id: yup.number().transform((value, originalvalue) => {
        if (originalvalue !== null) {
          value = originalvalue.id
          return value
        }
      }).nullable(),//.typeError('Please select a county').required('Required'),
      state_id: yup.number().transform((value, originalvalue) => {
        if (originalvalue !== null) {
          value = originalvalue.id
          return value
        }
      }).nullable()//.required('Required').typeError('Please select a state')
    })
  }),
  clinician: yup.object().shape({
    last_name: yup.string().nullable().default(''),
    first_name: yup.string().nullable().default(''),
    area_code: yup.string().nullable().matches(/^(\d\d\d)?$/, 'Must be a three-digit number'),
    phone_number: yup.string().nullable().matches(/^(\d{7})?$/, 'Must be seven digits')
  }),
  patient_search: yup.object().shape({
    last_name: yup.string(),
    first_name: yup.string(),
    dob: yup.string()
  }).nullable(),
  patient: yup.object().shape({
    last_name: yup.string().required('Required').max(50, 'Must be less than 50 characters'),
    first_name: yup.string().required('Required').max(50, 'Must be less than 50 characters'),
    middle_name: yup.string().nullable().default(''),
    dob: yup.string().required('Required')
      .test('', 'Please choose a valid date', (value, context) => {
        if (!(moment().diff(moment(value), 'years') <= 105)) { return context.createError({ path: 'patient.dob', message: 'Birth year cannot be more than 105 years ago' }) } else if (!(moment().diff(moment(value)) >= 0)) { return context.createError({ path: 'patient.dob', message: 'Cannot be a future date' }) }
        return true
      }),
    ethnicity_id: yup.number().transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id
        return value
      }
    }).nullable().default(3),
    area_code: yup.string().nullable().matches(/^(\d\d\d)?$/, 'Must be a three-digit number'),
    phone_number: yup.string().nullable().matches(/^(\d{7})?$/, 'Must be seven digits'),
    race_id: yup.number().transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id
        return value
      }
    }).nullable().default(7),
    gender_id: yup.number().transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id
        return value
      }
    }).required('Required').typeError('Please select a gender'),
    telephone_type_id: yup.number().transform((value, originalvalue) => {
      if (originalvalue !== null) {
        value = originalvalue.id
        return value
      }
    }).nullable().default(3),
    address: yup.object().shape({
      street: yup.string().required('Required'),
      city: yup.string().required('Required'),
      zip: yup.string().required('Required').matches(/^[0-9]{5}(-[0-9]{4})?$/, 'Zip code must be ##### or #####-#### format'),
      unit: yup.string().nullable().default(''),
      county_id: yup.number().transform((value, originalvalue) => {
        if (originalvalue !== null) {
          value = originalvalue.id
          return value
        }
      }).typeError('Please select a county').required('Required'),
      state_id: yup.number().transform((value, originalvalue) => {
        if (originalvalue !== null) {
          value = originalvalue.id
          return value
        }
      }).required('Required').typeError('Please select a state')
    })
  }),
  condition_id: yup.number().transform((value, originalvalue) => {
    if (originalvalue !== null) {
      value = originalvalue.id
      return value
    }
  }).required('Required'),

  facility_visit_type_id: yup.number().transform((value, originalvalue) => {
    if (originalvalue !== null) {
      value = originalvalue.id
      return value
    }
  }).required('Required'),

  specimen_source_id: yup.number().transform((value, originalvalue) => {
    if (originalvalue !== null) {
      value = originalvalue.id
      return value
    }
  }).required('Required').typeError('Please select'),

  specimen_sent_at: yup.string().required('Required')
    .test('', 'Please choose a valid date', (value, context) => {
      if (!(moment().diff(moment(value), 'years') <= 5)) {
        return context.createError({ path: 'specimen_sent_at', message: 'Cannot be more than 5 years ago' })
      } else if (!(moment().diff(moment(value)) >= 0)) {
        return context.createError({ path: 'specimen_sent_at', message: 'Cannot be a future date' })
      }
      specimenDate = value
      return true
    }),

  lab_test: yup.object().shape({
    lab: yup.string().required('Required'),
    test_date: yup.string()
      .required('Required when test results have been received')
      .test('', 'Please choose a valid date', (value, context) => {
        if (specimenDate === null || specimenDate === undefined) {
          return context.createError({ path: 'lab_test.test_date', message: 'No specimen sent' })
        } else if (!(moment(specimenDate).diff(moment(value)) <= 0)) {
          return context.createError({ path: 'lab_test.test_date', message: 'Test date cannot be before specimen sent date' })
        } else if (!(moment().diff(moment(value)) >= 0)) {
          return context.createError({ path: 'lab_test.test_date', message: 'Cannot be a future date' })
        }
        return true
      }),
    lab_test_results: yup.array().of(yup.object().shape({
      test_type_id: yup.number()
        .transform((value, originalvalue) => {
          if (originalvalue !== null) {
            value = originalvalue.id
            return value
          }
        }).required('Required').typeError('Please select'),
      test_result_id: yup.number()
        .transform((value, originalvalue) => {
          if (originalvalue !== null) {
            value = originalvalue.id
            return value
          }
        }).required('Required').typeError('Please select')
    }))
  })
})

export default function ShowCase (props) {
  // React Router Hook variables:
  const params = useParams()
  const navigate = useNavigate()
  const readonly = !!params.case

  // console.log(params)
  // Validation variables:
  // Allow error messages on all actions ie. onClick, onBlur, etc.
  const formOpts = { mode: 'all' }
  // If params.case is a number, display it. Else new case.
  if (isNaN(params.case)) {
    formOpts.resolver = yupResolver(formSchema)
  }
  // Operations tied to the form validation
  const {
    setValue, watch, control, register, reset,
    handleSubmit, formState: { errors }, getValues
  } = useForm(formOpts)


  // Custom Styles:
  const classes = useStyles()

  // State variables:
  const [thisCase, setThisCase] = React.useState({})
  const [loadedCase, setLoadedCase] = React.useState(false)
  const [loaded, setLoaded] = React.useState(false)
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [caseLoaded, setCaseLoaded] = React.useState(false);
  const [clinLastName, setClinLastName] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)

  const patientSearch = useSelector(state => state.patientSearch)

  // Context variables:
  const { labs, specimenSources, sourcesLoaded } = React.useContext(SourcesContext)
  const { currentUser } = React.useContext(UserContext)
  const snackbarCtx = React.useContext(SnackbarContext)
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const {
    states, counties, telephoneTypes, gender,
    ethnicities, races, conditions, facilityVisitTypes,
    labTypes, testTypes, testResultValues
  } = React.useContext(SourcesContext)
  const defaultState = states?.find(s => s.code === process.env.DEFAULT_STATE)

  // Rendering of Test Result fields in show case.
  const shouldShowTestResults = c => {
    if (params.case) {
      return c.lab_test !== null && c.lab_test_results !== null // Existing case. If there are test results, set this to true
    } else {
      return false // New case. Default to false
    }
  }

  // Effect sets defaults for a new or existing case
  React.useEffect(() => {
    if (props.new) {
      setError(false);
    }
  }, [props.new]);

  React.useEffect(() => {
    if (!sourcesLoaded) { return }
    setLoadedCase(params.case)

    if (params.case) {
      // Set defaults for when we've selected a case
      axios.get('/notifications/' + params.case).then(resp => {
        setCaseLoaded(true);
        if (resp.data.status == "forbidden") {
          setError(true);
          setErrorMessage(resp.data?.message);
          return;
        }
        if (
          resp.data?.status == "ok" &&
          Object.keys(resp.data?.data).length === 0
        ) {
          setError(true);
          setErrorMessage(resp.data?.message);
          return;
        }
        const t = resp.data.data
        setThisCase(t)
        const defaultValues = {
          facility_id: t.facility ? t.facility : '',
          facility: {
            name: t.facility ? t.facility.name : '',
            address: {
              street: (t.facility_address && t.facility_address.street) ? t.facility_address.street : '',
              unit: (t.facility_address && t.facility_address.unit) ? t.facility_address.unit : '',
              city: (t.facility_address && t.facility_address.city) ? t.facility_address.city : '',
              zip: (t.facility_address && t.facility_address.zip) ? t.facility_address.zip : '',
              county_id: (t.facility_address && t.facility_address.county_id) ? counties.find(county => county.id === t.facility_address.county_id) : '',
              state_id: (t.facility_address && t.facility_address.state_id) ? states.find(state => state.id === t.facility_address.state_id) : ''
            }
          },

          clinician: {
            last_name: t.clinician.last_name || '',
            first_name: t.clinician.first_name || '',
            phone: formatPhone(t.clinician.area_code, t.clinician.phone_number) || ''
          },

          patient: {
            last_name: t.patient.last_name || '',
            first_name: t.patient.first_name || '',
            middle_name: t.patient.middle_name || '',
            dob: t.patient.dob || '',
            telephone_type_id: (t.patient && t.patient.telephone_type_id) ? telephoneTypes.find(s => s.id === t.patient.telephone_type_id) : '',
            phone: formatPhone(t.patient.area_code, t.patient.phone_number) || '',
            gender_id: (t.patient && t.patient.gender_id) ? gender.find(s => s.id === t.patient.gender_id) : '',
            ethnicity_id: (t.patient && t.patient.ethnicity_id) ? ethnicities.find(s => s.id === t.patient.ethnicity_id) : '',
            race_id: (t.patient && t.patient.race_id) ? races.find(s => s.id === t.patient.race_id) : '',
            address: {
              street: (t.patient_address && t.patient_address.street) ? t.patient_address.street : '',
              unit: (t.patient_address && t.patient_address.unit) ? t.patient_address.unit : '',
              city: (t.patient_address && t.patient_address.city) ? t.patient_address.city : '',
              zip: (t.patient_address && t.patient_address.zip) ? t.patient_address.zip : '',
              county_id: (t.patient_address && t.patient_address.county_id) ? counties.find(s => s.id === t.patient_address.county_id) : '',
              state_id: (t.patient_address && t.patient_address.state_id) ? states.find(s => s.id === t.patient_address.state_id) : ''
            }
          },

          condition_id: t.notification.condition_id ? conditions.find(s => s.id === t.notification.condition_id) : '',
          facility_visit_type_id: t.notification.facility_visit_type_id ? facilityVisitTypes.find(s => s.id === t.notification.facility_visit_type_id) : '',

          specimen_sent: t.notification.specimen_sent ? { id: 1, name: 'Yes', value: true } : { id: 2, name: 'No', value: false },
          specimen_source_id: t.notification.specimen_source_id ? specimenSources.find(s => s.id === t.notification.specimen_source_id) : '',

          lab_type_id: t.notification.lab_type_id ? labTypes.find(s => s.id === t.notification.lab_type_id) : '',

          specimen_sent_at: (t.notification.specimen_sent_at && moment(t.notification.specimen_sent_at).format('YYYY-MM-DD')) || '',

          comment: t.notification.comment,

          is_test_resulted: { id: 1, name: 'Yes', value: true },
          lab_test: {
            lab: ((t.lab_test && t.lab_test.lab_id) ? labs.find(l => l.id === t.lab_test.lab_id) : ''),
            test_date: (t.lab_test && moment(t.lab_test.test_date).format('YYYY-MM-DD')) || '',
            lab_test_results:
              t.lab_test_results
                ? t.lab_test_results.map(r => {
                  return {
                    test_type_id: (r.test_type_id ? testTypes.find(tt => tt.id === r.test_type_id) : {}),
                    test_result_id: (r.test_result_id ? testResultValues.find(tt => tt.id === r.test_result_id) : {})
                  }
                })
                : []
          }
        }
        reset(defaultValues)
        if(t.lab_test_results) {
          t.lab_test_results.map((r, i) => {
            setValue(`lab_test.lab_test_results[${i}].test_type_id`, (r.test_type_id ? testTypes.find(tt => tt.id === r.test_type_id) : {}))
            setValue(`lab_test.lab_test_results[${i}].test_result_id`, (r.test_result_id ? testResultValues.find(tt => tt.id === r.test_result_id) : {}))
          })
        }
      })
      .catch((err) => {
        setError(true);
        setErrorMessage("The requested case does not exist.");
        setCaseLoaded(true);
      });
    } else {
      // Set defaults for a new case
      setThisCase({})
      resetAll()
    }
  }, [params, setThisCase, setValue, sourcesLoaded, setLoadedCase])

  // Effect sets state loaded to true if case defaults have been set
  React.useEffect(() => {
    if ((caseLoaded || !params.case) && sourcesLoaded) {
      setLoaded(true);
    }
  }, [caseLoaded, sourcesLoaded])

  const submit = (e) => {
    if(submitting) {
      return;
    }
    setSubmitting(true)
    console.log('Submitting', e)
    delete e['patient_search']
    e['current_user_id'] = currentUser.id
    if(patientSearch.patientSelected && patientSearch.patientId) {
      e['patient_id'] = patientSearch.patientId
      delete e['patient']
    }
    axios.post('/notifications/', { notification: e })
      .then(response => {
        props.getSources();
        snackbarCtx.showSnackbar(response.data.message)
        setThisCase(response.data.notification)
        console.log(response.data.notification)
        setSubmitting(false)
        navigate(pathname(true) + 'case/' + response.data.notification?.notification_id + '?submitted=true')
      })
      .catch(error => {
        setSubmitting(false)
        snackbarCtx.showSnackbar('Problem with creating case')
        console.log(error)
      })
  }

  const populatePatient = (patient) => {
    setValue('patient.first_name', patient.patient_first_name)
    setValue('patient.last_name', patient.patient_last_name)
    setValue('patient.middle_name', patient.patient_middle_name)
    setValue('patient.dob', patient.patient_dob)
    setValue('patient.gender_id', patient.gender)
    setValue('patient.race_id', patient.race)
    setValue('patient.ethnicity_id', patient.ethnicity)
    setValue('patient.telephone_type_id', patient.telephone_type)
    setValue('patient.address.street', patient.street)
    setValue('patient.address.city', patient.city)
    setValue('patient.address.zip', patient.zip)
    setValue('patient.address.unit', patient.unit)
    setValue('patient.address.county_id', patient.county)
    setValue('patient.area_code', patient.area_code)
    setValue('patient.phone_number', patient.phone_number)
  }

  const resetPatient = () => {
    setValue('patient', {
      first_name: '',
      last_name: '',
      middle_name: '',
      dob: '',
      gender_id: '',
      race_id: '',
      ethnicity_id: '',
      telephone_type_id: '',
      address: {
        street: '',
        city: '',
        zip: '',
        unit: '',
        county_id: '',
      },
      area_code: '',
      phone_number: ''
    })
  }

  const resetAll = () => {

    const defaultValues = {
      facility_id: currentUser.facilities[0],
      facility: {
        name: currentUser.facilities[0].name || '',
        address: {
          street: currentUser.facilities[0].full_address?.street || '',
          unit: currentUser.facilities[0].full_address?.unit || '',
          city: currentUser.facilities[0].full_address?.city || '',
          zip: currentUser.facilities[0].full_address?.zip || '',
          county_id: counties.find((county) => county.id === currentUser.facilities[0].full_address?.county_id) || '',
          state_id: states.find((state) => state.id === currentUser.facilities[0].full_address?.state_id) || ''
        }
      },
      clinician: {
        area_code: currentUser.facilities[0].area_code || '',
        phone_number:  currentUser.facilities[0].phone_number || ''
      },
      lab_test: {
        lab_test_results: []
      },
    }
    reset(defaultValues);
    resetPatient()
    const fields = [
      'clinician.first_name',
      'clinician.last_name',
      'patient_search.first_name',
      'patient_search.last_name',
      'patient_search.dob',
      'facility_visit_type_id',
      'specimen_source_id',
      'specimen_sent_at',
      'comment',
      'lab_test.lab',
      'lab_test.test_date',
    ]
    fields.forEach(c => setValue(c, ''))
    setClinLastName(true)
    getValues('lab_test.lab_test_results') && 
      getValues('lab_test.lab_test_results').map((l, i) => {
        setValue(`lab_test.lab_test_results[${i}].test_type_id`, '')
        setValue(`lab_test.lab_test_results[${i}].test_result_id`, '')
      })
    setValue('lab_test.lab_test_results', [])
    setValue('specimen_sent', true)
    setValue('lab_type_id', 1)
    setValue('is_test_resulted', true)
    setValue('patient.address.state_id', defaultState)
    setValue('condition_id', { id: 1, name: 'Coronavirus Disease 2019 (COVID-19)', code: '279' })
  }

  const childProps = {
    caseId: params.case,
    readonly,
    setValue,
    getValues,
    errors,
    register: useMuiRegister(register),
    control,
    watch,
    currentUser,
    clinLastName,
    setClinLastName
  }

  const childPropsPatientFilter = {
    ...childProps,
    populatePatient,
    resetPatient
  }


  return (
    <div>
      {
        !loaded
          ? (<Loading />)
          : !error ? (
              <>
                { !!params.case && (
                  <div className={classes.topPrintBar}>
                      <ReactToPrint
                        trigger={() => <Button color="secondary" variant="contained">Print Report</Button>}
                        content={() => props.printRef.current}
                      />
                      <div>
                        <p className='m-0'><b>Submitter: </b>
                          {thisCase?.creator?.last_name}, {thisCase?.creator?.first_name} -
                          <a className='pl-1' href={`mailto:${thisCase?.creator?.username}`}>
                            {thisCase?.creator?.username}
                          </a></p>
                      </div>
                  </div>
                )}
                { (query.get('submitted') === 'true') && (
                  <div className={classes.submitNote}>
                    <div> NOTIFICATION SUBMITTED </div>
                    <div> Please print for your records</div>
                  </div>
                )}
                <form id='new-report-form' className='mt-3' onSubmit={handleSubmit(submit)}>
                  { (loadedCase === params.case) && (
                    <FormControl fullWidth>
                      <ClinicianInformation data-testid="ClinicianInformation" {...childProps} />
                      {
                        !params.case && <PatientFilter {...childPropsPatientFilter} />
                      }
                      {
                        !params.case && patientSearch.searched && <PatientSearchResults {...childPropsPatientFilter} />
                      }
                      {
                        !params.case && patientSearch.searched && 
                        <div className='pt-1 d-flex justify-end'>
                          <Button onClick={resetPatient} variant="contained" color="secondary">
                            Reset
                          </Button>
                        </div>
                      }
                      <PatientInformation {...childProps} />
                      <DiseaseInformation {...childProps} />
                      <TestResultsInformation {...childProps} />
                    </FormControl>
                  )}
                  { !params.case && (
                    <Card className="mt-3" elevation={0} variant="outlined">
                      <CardContent>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Button variant="contained" color="primary" onClick={() => resetAll()}>Reset</Button>
                          </Grid>
                          <Grid item>
                            <Button variant="contained" color="secondary" data-testid="save" type="submit">Submit</Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                  { (query.get('submitted') === 'true') && (
                    <Card className="mt-3" elevation={0} variant="outlined">
                      <CardContent>
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Button variant="contained" color="secondary" onClick={() => navigate(pathname(true) + 'newcase')}>NEW REPORT</Button>
                          </Grid>
                          <Grid item>
                            <Button variant="contained" color="primary" onClick={() => navigate(pathname(true))}>DASHBOARD</Button>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </form>
              </>
            ) : (
              <Card className="mt-3" elevation={0} variant="outlined">
                <CardContent>
                  <p className="font-bold text-center">{errorMessage}</p>
                </CardContent>
              </Card>
            )
      }
    </div>
  )
}
ShowCase.propTypes = {
  printRef: PropTypes.object
}
