import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import axios from 'axios'
import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom';
import { compileString } from 'sass';
import api from '../../config/axios.config';
import SnackbarContext from '../../lib/context/SnackbarContext';

export default function ShowRole () {

    const [privileges, setPrivileges] = useState([])
    const [role, setRole] = useState('')
    const [readOnly, setReadOnly] = useState(true)
    const [loading, setLoading] = useState(false)
    const [defaultPrivileges, setDefaultPrivileges] = useState([]);
    const params = useParams();
    const snackbarCtx = useContext(SnackbarContext);

    useEffect(() => {
        getRole();
    }, [])

    const getRole = async() => {
        const _privileges = (await api.get('/api/privileges')).data;
        api.get(`/api/roles/${params.role}`)
        .then((resp) => {
            const rolePrivileges = resp.data?.privileges;
            setRole(resp.data?.role?.name)
            _privileges.forEach((privilege, index) => {
                let assigned = false;
                rolePrivileges.forEach((rolePrivilege) => {
                    if(privilege.id === rolePrivilege.privilege_id) {
                        assigned = true
                        privilege['role_privilege_id'] = rolePrivilege['id'];
                    }
                 })
                 assigned ? privilege['assigned'] = true : privilege['assigned'] = false
            })
            setPrivileges(_privileges)
            const defaultz = JSON.parse(JSON.stringify(_privileges))
            setDefaultPrivileges(defaultz);
        }) // add error catching?
    }

    const handleChange = (event) => {
      const _priveleges = JSON.parse(JSON.stringify(privileges))
      const newPrivileges = _priveleges.map((privilege) => {
        if (privilege.name === event.target.name) {
          privilege.assigned = event.target.checked
        }
        return privilege
      });
      setPrivileges(newPrivileges)
    };

    const handleCancel = () => {
      setPrivileges(defaultPrivileges);
      setReadOnly(true);
    }

    const save = () => {
      const body = {
        privileges: privileges,
        role_id: params.role
      }
      setLoading(true)
      api.patch(`/api/privileges`, body)
      .then((resp) => {
        setLoading(false);
        setReadOnly(true);
        snackbarCtx.showSnackbar(resp.data.message);
      })
      .catch((err) => {
        setLoading(false);
        setReadOnly(true);
        snackbarCtx.showSnackbar("Something went wrong");
      })
    }

  return (
      <>
      <div className='d-flex justify-between'>
      <p className='m-0 card-header'>{role} - PRIVILEGES</p>
        <div>
        {readOnly && (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => setReadOnly(false)}
                    >
                      Edit
                    </Button>
                  )}
                  {!readOnly && (
                    <div>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        className="ml-3"
                        color="secondary"
                        variant="contained"
                        onClick={save}
                        loading={loading}
                      >
                        Save
                      </LoadingButton>
                    </div>
                  )}
        </div>
      </div>
      <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
        <FormGroup>
          {
              privileges.map((privilege, index) => {
                  return (
                    <FormControlLabel key={index}
                    control={
                      <Checkbox checked={privilege.assigned} onChange={handleChange} name={privilege.name} disabled={readOnly} />
                    }
                    label={privilege.name}
                  />
                  )
              })
          }
        </FormGroup>
      </FormControl>
      </>
  )
}
