import React from 'react'

import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

export default function Loading () {
  return (
    <div style={{ width: '100%', margin: '0px auto' }}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Loading...</Typography>
        </Grid>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    </div>
  )
}
