import React from 'react'

import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import SnackbarContext from '../lib/context/SnackbarContext'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import axios from '../lib/axios'

import { useMuiRegister } from '../lib/misc'

// TODO: Validation
/*
const validationRules = {
  password: [
    { rule: f => !!f, message: 'Password is required' },
    { rule: f => /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/.test(f), message: 'Password must meet requirements below' },
    { rule: f => f.length >= 8, message: 'Password must meet requirements below' }
  ]
}
*/

export default () => {
  const { handleSubmit, register, getValues, formState: { errors } } = useForm({ mode: 'onBlur' })
  const navigate = useNavigate()
  const params = useParams()
  const { showSnackbar } = React.useContext(SnackbarContext)
  const muiRegister = useMuiRegister(register)
  const relativeURI = process.env.RELATIVE_URI

  const doSubmit = (values) => {
    if (!params.token) {
      // This is probably unreachable code; react-router seems unlikely to let
      // us get here if the token is null
      showSnackbar('Invalid password reset token')
      return
    }

    const postData = {
      password: values.password,
      token: params.token
    }
    axios.post('/session/password_reset', postData).then(resp => {
      showSnackbar('You have successfully reset your password')
      navigate(relativeURI)
    }).catch(err => {
      showSnackbar('Password reset error: ' + err.response.data.error || 'Unknown')
    })
    /*
      if (this.$refs.form.validate() && this.verifyToken()) {
        this.HTTP.post('/user/password_reset', {
          password: this.password, token: this.$route.params.token
        }).then(response => {
          this.snackbarValue.message = 'You have successfully reset your password'
          this.setSnackbar(this.snackbarValue)
          this.$router.push({ path: '/' })
        }).catch(error => {
          if (error) {
            this.snackbarValue.message = error.response.data.error
            this.setSnackbar(this.snackbarValue)
          }
        })
      } else {
        this.snackbarValue.message = 'Invalid password reset code'
        this.setSnackbar(this.snackbarValue)
      }
    },
    verifyToken () {
      return this.$route.params.token != null
    }
    */
  }

  return (
    <form onSubmit={handleSubmit(doSubmit)}>
      <FormControl fullWidth>
        <Card variant="outlined" style={{ margin: '0px auto', width: '700px' }}>
          <CardContent>
            <Typography variant="h5">Reset Password</Typography>
            <Grid container>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField style={{ paddingRight: '10px' }}
                    type="password" name="password" label="Password" autoComplete="new-password" variant="standard"
                    {...muiRegister('password', {
                      required: true,
                      minLength: { value: 8, message: 'Password must meet requirements below' },
                      pattern: { value: /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/, message: 'Password must meet requirements below' }
                    })}
                    error={errors.password} helperText={errors.password && errors.password.message}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField style={{ paddingLeft: '10px' }}
                    type="password" name="confirm_password" variant="standard"
                    label="Confirm password" autoComplete="new-password"
                    {...muiRegister('confirm_password', { validate: v => v === getValues().password || 'Passwords must match' })}
                    error={errors.confirm_password}
                    helperText={errors.confirm_password && errors.confirm_password.message}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={8} style={{ paddingTop: '10px' }}>
                <Box textAlign="center" fontStyle="italic" fontSize="10">
                  <Typography variant="subtitle2">Password must be minimum 8 characters, contain a letter, number and special character [*,%,$]</Typography>
                </Box>
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </CardContent>

          <CardActions>
            <Button onClick={() => navigate('/')} variant="contained">Close</Button>
            <Button color="primary" variant="contained" type="submit">
              Submit
            </Button>
          </CardActions>
        </Card>
      </FormControl>
    </form>
  )
}
