import React from 'react'
import PropTypes from 'prop-types'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import RHFMultiSelect from './RHFMultiSelect'

import SourcesContext from '../lib/context/SourcesContext'
import { useSelector } from 'react-redux'

export default function PatientSelects (props) {
  const { telephoneTypes, gender, ethnicities, races, sourcesLoaded } = React.useContext(SourcesContext)
  const patient = useSelector((state) => state.patientSearch);
  const readonly = (patient.patientSelected || props.readonly)

  const patientPhone = (
    readonly
      ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="patient.phone" inputProps={{ readOnly: readonly }}
                InputLabelProps={{ shrink: props.watch('patient.phone') !== '' }} variant="standard"
                label="Phone Number" {...props.register('patient.phone')}
              />
            </Grid>
          </Grid>
        )
      : (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField 
                name="patient.area_code" inputProps={{ readOnly: readonly }} InputLabelProps={{ shrink:props.watch('patient.area_code') !== ''  }}
                label="Area Code" variant="standard"
                error={!!props.errors.patient?.area_code} helperText={props.errors.patient?.area_code && props.errors.patient?.area_code.message}
                {...props.register('patient.area_code')}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="patient.phone_number" inputProps={{ readOnly: readonly }} InputLabelProps={{ shrink:props.watch('patient.phone_number') !== ''  }}
                label="Phone Number" variant="standard"
                error={!!props.errors.patient?.phone_number} helperText={props.errors.patient?.phone_number && props.errors.patient?.phone_number.message}
                {...props.register('patient.phone_number')}
              />
            </Grid>
          </Grid>
        )
  )

  return (
    <>
      <Grid className='pt-3' container spacing={2}>
        <Grid item xs={3}>
          { sourcesLoaded && (
            <RHFMultiSelect
              name="patient.telephone_type_id" fullWidth register={props.register}
              readOnly={readonly}
              addBlankOption={true}
              objGOS={true}
              label="Phone Type" control={ props.control }
              options={telephoneTypes}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          { patientPhone }
        </Grid>
      </Grid>

      <Grid className='pt-3' container spacing={2}>
        <Grid item xs={2}>
          { (sourcesLoaded) && (
            <RHFMultiSelect
              name="patient.gender_id" fullWidth register={props.register}
              readOnly={readonly}
              addBlankOption={true}
              label="Gender *" control={ props.control }
              objGOS={true}
              options={gender}
              error={!!props.errors.patient?.gender_id} helperText={props.errors.patient?.gender_id && props.errors.patient?.gender_id.message}
              inputProps={{"data-testid": "patient.gender_id"}}
            />
          )}
        </Grid>
        <Grid item xs={3}>
          { sourcesLoaded && (
            <RHFMultiSelect
              name="patient.ethnicity_id" fullWidth register={props.register}
              readOnly={readonly}
              addBlankOption={true}
              label="Ethnicity" control={ props.control }
              objGOS={true}
              options={ethnicities}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          { sourcesLoaded && (
            <RHFMultiSelect
              name="patient.race_id" fullWidth register={props.register}
              readOnly={readonly}
              addBlankOption={true}
              label="Race (Select 'Other' for multiple races)"
              objGOS={true}
              control={ props.control }
              options={races}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
PatientSelects.propTypes = {
  control: PropTypes.object,
  readonly: PropTypes.bool,
  register: PropTypes.func,
  errors: PropTypes.shape({
    patient: PropTypes.shape({
      phone_number: PropTypes.object,
      area_code: PropTypes.object,
      gender_id: PropTypes.object
    })
  })
}
