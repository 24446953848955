import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPatientId, setPatientSelected } from "../store/PatientSearch/patientSearch.actions";

export default function PatientSearchResults(props) {
  const patients = useSelector((state) => state.patientSearch.patients);
  const dispatch = useDispatch();
  const linkToCase = (params) => {
    return <p>{params.patient_full_name}</p>;
  };

  const formatDate = (s) => {
    if (!s) return null;
    return moment(s).format("MM/DD/YYYY");
  };

  const columns = [
    { field: "patient_full_name", headerName: "Name", renderCell: linkToCase },
    {
      field: "patient_dob",
      headerName: "Date of Birth",
      valueFormatter: formatDate,
    },
    { field: "gender_name", headerName: "Gender" },
  ];

  const selectPatient = (patient) => {
    dispatch(setPatientSelected(true));
    dispatch(setPatientId(patient.patient_id))
    props.populatePatient(patient);
  };

  return (
    <TableContainer style={{ maxHeight: 200 }}>
      <Table
        sx={{ minWidth: 650 }}
        aria-label="simple table"
        stickyHeader={true}
      >
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              >
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {
          patients.length > 0 ? (
          <TableBody>
            {patients.map((patient, index) => {
              return (
                <TableRow
                  className="cursor-pointer patient-row"
                  key={index}
                  onClick={() => selectPatient(patient)}
                >
                  {columns.map((column, index) => {
                    return column?.renderCell ? (
                      <TableCell style={{ padding: "0px" }} key={index}>
                        {column.renderCell(patient)}
                      </TableCell>
                    ) : column?.valueFormatter ? (
                      <TableCell style={{ padding: "0px" }} key={index}>
                        {formatDate(patient?.[column?.field])}
                      </TableCell>
                    ) : (
                      <TableCell style={{ padding: "0px" }} key={index}>
                        {patient[column.field]}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
          ) : (
            <TableBody>
              <TableCell colSpan={3} align="center">No match found.</TableCell>
            </TableBody>
          )
        }
      </Table>
    </TableContainer>
  );
}
