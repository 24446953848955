const relativeURI = process.env.RELATIVE_URI

export const PATHS = {
  DASHBOARD: relativeURI,
  NEW_REPORT: relativeURI + 'newcase',
  BULK_UPLOAD: relativeURI + 'bulkupload',
  SEARCH_PATIENTS: relativeURI + 'patient/search',
  USERS: relativeURI + 'users',
  USER: relativeURI + 'users',
  FACILITIES: relativeURI + 'facilities',
  ROLES: relativeURI + 'roles',
  CONDITIONS: relativeURI + 'conditions'
}

export const API_PATH = {
  SEARCH_USERS: '/user/search'
}