import moment from 'moment'

import { SET_COUNT, SET_NOTIFICATIONS, SET_PAGE, SET_SEARCH_PARAMS } from './notification.types'

const INITIAL_STATE = {
  limit: 25,
  count: 0,
  page: 0,
  params: {
    lastName: '',
    fromDate: moment().format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    selectedConditions: [],
    selectedFacilities: [],
    selectedCounties: []
  },
  notifications: []
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state, page: action.page
      }
    case SET_COUNT:
      return {
        ...state, count: action.count
      }
    case SET_SEARCH_PARAMS:
      return {
        ...state, params: action.params
      }
    case SET_NOTIFICATIONS:
      return {
        ...state, notifications: action.notifications
      }
    default:
      return state
  }
}

export default reducer
