import { SET_FACILITIES, SET_FACILITY_COUNT, SET_FACILITY_PAGE, SET_FACILITY_SEARCH_PARAMS } from './facilityManagement.types'

const INITIAL_STATE = {
  limit: 25,
  count: 0,
  page: 0,
  params: {
    facilities: [],
    active: true,
    bulk_upload: true
  },
  facilities: []
}

const facilities = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FACILITY_PAGE:
      return {
        ...state, page: action.page
      }
    case SET_FACILITY_COUNT:
      return {
        ...state, count: action.count
      }
    case SET_FACILITY_SEARCH_PARAMS:
      return {
        ...state, params: action.params
      }
    case SET_FACILITIES:
      return {
        ...state, facilities: action.facilities
      }
    default:
      return state
  }
}

export default facilities
