import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button'

// Possible alternative date picker, if we want one: https://reactdatagrid.io/demo
import { useSelector, useDispatch } from 'react-redux'

import Loading from './Loading'
import NotificationSearchFields from './NotificationSearchFields'

import { formatDate, pathname } from '../lib/misc'

import UserContext from '../lib/context/UserContext'
import axios from 'axios'
import { setCount, setNotifications, setPage } from '../store/Notification/notification.actions'
import { constructNotificationSearchParams } from '../helpers/notificationHelper'
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@mui/material'
import FacilityUpdatePopup from './FacilityUpdatePopup'
import api from '../config/axios.config';
/*
TODO: validation rules for date selectors
  - Play with this and see if it's possible to get dates in an unusable format
  - Also see if date format matters for the search
*/

const useStyles = makeStyles({
  fullWidthCentered: {
    width: '100%',
    textAlign: 'center'
  },
  tableRow: {
    padding: '0px'
  }
})

const linkToCase = (params) => {
  return (
    <Link to={pathname(true) + 'case/' + params.id}>{ params.patient_full_name}</Link>
  )
}

/* Original date formatter from Vue portal:

const formatDate = function (params) {
  const date = params.value;
  if (!date) return null

  const [year, month, day] = date.slice(0, 10).split('-')
  return `${month}/${day}/${year}`
};

*/

export default function Dashboard () {
  const { currentUser } = React.useContext(UserContext)
  const [loading, setLoading] = React.useState(false)
  const classes = useStyles()
  const navigate = useNavigate()

  const [registerDialog, setRegisterDialog] = React.useState(true)
  const dialogClose = (event, reason) => {
    // Don't close the dialog by clicking outside it
    if (reason !== 'backdropClick') { setOpen(false) }
  }

  const notifications = useSelector(state => state.notifications)
  const dispatch = useDispatch()
  const updatePage = async (page) => {
    const params = constructNotificationSearchParams(notifications.params, page)
    dispatch(setPage(page));
    api.get('/notifications/search.json', { params })
      .then((resp) => {
        dispatch(setNotifications(resp.data.notifications))
        dispatch(setCount(resp.data.total_entries))
      })
  }

  const testResultCell = (params) => {
    const onClick = () => {
      navigate(pathname(true) + 'case/' + params.id + '-edit')
    }
    if (params.test_result_name) {
      return (params.test_result_name)
    } else if (params.specimen_sent_at) {
      return (<Button variant="contained" color="primary" onClick={onClick}>Add</Button>)
    } else {
      return ('No Specimen Sent')
    }
  }

  const columns = [
    { field: 'patient_full_name', headerName: 'Name', renderCell: linkToCase, width: '15%', sortable: false },
    { field: 'patient_dob', headerName: 'DOB', valueFormatter: formatDate, width: '10%', sortable: false },
    { field: 'condition_name', headerName: 'Condition', width: 270, width: '20%', sortable: false },
    { field: 'test_result_name', headerName: 'Result', renderCell: testResultCell, width: '10%', sortable: false, width: 140 },
    { field: 'facility_name', headerName: 'Facility Name', flex: true, sortable: false,  width: '15%' },
    { field: 'county_name', headerName: 'County', sortable: false, width: '10%' },
    { field: 'specimen_sent_at', headerName: 'Collection Date', valueFormatter: formatDate, sortable: false, width: '10%' },
    { field: 'created_at', headerName: 'Created', valueFormatter: formatDate, sortable: false, width: '10%' }
  ]

  return (
    <>
      <NotificationSearchFields setLoading={setLoading} />
      <FacilityUpdatePopup/>
      <div className='patientNotifications mt-2'>
        <div className='text-center pt-3'>
          <p className='m-0 card-header'>
                PATIENT CASE NOTIFICATIONS
              { (currentUser && currentUser.permission === 'facility') && (' - ' + currentUser.facilities[0].name) }
          </p>
        </div>
        <div className='notificationsTable pt-3'>
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
          {
            (loading
              ? (<Loading />)
              : ( notifications.notifications && 
                  <TableContainer>
                    <Table size="medium" stickyHeader> 
                      <colgroup>
                          {
                            columns.map((column, index) => (
                              <col key={index} width={column.width} />
                            ))
                          }
                      </colgroup>
                      <TableHead className='tableHeader'>
                        <TableRow >
                          {columns.map((column, index) => (
                              <TableCell
                                key={index}
                                align='left'
                              >
                                {column.headerName}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                          {
                          notifications.notifications.map((notification, index) => {
                            return (
                              <TableRow key={index}>
                                {
                                  columns.map((column, index) => {
                                    return (
                                      column?.renderCell ? (
                                        <TableCell style={{ padding: '0px'}} align='left' key={index}>
                                          {column.renderCell(notification)}
                                        </TableCell>
                                      ) : column?.valueFormatter ? (
                                        <TableCell style={{ padding: '0px'}} align='left' key={index}>
                                          { formatDate(notification?.[column?.field]) }
                                        </TableCell>
                                      ) : (
                                        <TableCell style={{ padding: '0px'}} align='left' key={index}>
                                          {notification[column.field]}
                                        </TableCell>
                                      )
                                    )
                                  })
                                }
                              </TableRow>
                            )
                          })
                        }
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                        <TablePagination
                          count={notifications.count}
                          rowsPerPage={25}
                          rowsPerPageOptions={[]}
                          page={notifications.page}
                          onPageChange={(e, newPage) => updatePage(newPage)}
                        />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
                )
            )
          }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
