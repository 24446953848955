import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";
import axios from "axios";
import { PATHS } from "../constants/uri.constants";
import { useDispatch } from "react-redux";
import {
  setPatientSearched,
  setPatientSearchResults,
  setPatientSelected
} from "../store/PatientSearch/patientSearch.actions";

export default function PatientFilter(props) {
  const dispatch = useDispatch();

  const search = () => {
    const params = new URLSearchParams();
    const values = props.getValues().patient_search;
    if (values.first_name) {
      params.append("first_name", values.first_name);
    }
    if (values.last_name) {
      params.append("last_name", values.last_name);
    }
    if (values.dob) {
      params.append("dob", values.dob);
    }
    axios.get(PATHS.SEARCH_PATIENTS, { params }).then((value) => {
      dispatch(setPatientSearched(true));
      dispatch(setPatientSearchResults(value.data));
      props.populatePatient(value.data);
    });
  };

  const resetValues = () => {
    props.setValue("patient_search.first_name", "");
    props.setValue("patient_search.last_name", "");
    props.setValue("patient_search.dob", "");
    dispatch(setPatientSearchResults([]));
    dispatch(setPatientSearched(false));
    dispatch(setPatientSelected(false))
    props.resetPatient();
  };

  return (
    <Card className="mt-3" variant="outlined">
      <CardContent>
        <p className="m-0 card-header">PATIENT SEARCH</p>
        <Grid className="pt-3" container spacing={2}>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              name="last_name"
              label="Last Name"
              variant="standard"
              {...props.register("patient_search.last_name")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              name="first_name"
              label="First Name"
              variant="standard"
              {...props.register("patient_search.first_name")}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              className="w-100"
              variant="standard"
              name="dob"
              type="date"
              InputLabelProps={{ shrink: true }}
              pattern="\d{4}-\d{2}-\d{2}"
              label="Date of Birth"
              defaultValue={props.getValues().patient_search.dob}
              {...props.register("patient_search.dob")}
            />
          </Grid>
          <Grid container item xs={2} justifyContent="space-between">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => resetValues()}
              >
                Reset
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={search} variant="contained" color="secondary">
                Search
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
