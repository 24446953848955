import axios from '../lib/axios'
import store from '../store/store'
import { setFacilities,  setFacilityCount} from '../store/FacilityManagement/facilityManagement.actions';

const state = store.getState()
const facilities = state.facilities

export const getFacilities = (_params, page = 0) => {
  const params = constructFacilitySearchParams(_params, page)
  axios
    .get('/api/facilities/search', { params })
    .then(response => {
      console.log(response)
      store.dispatch(setFacilities(response.data.facilities))
      store.dispatch(setFacilityCount(response.data.total_entries))
    })
    .catch(e => {
      console.log(e)
    })
}

export const constructFacilitySearchParams = (e, page = 0) => {
  const params = new URLSearchParams()
  if (e?.facilities) {
    params.append('facilities', e.facilities)
  }
  if(typeof e?.active !== 'undefined') {
    params.append('active', e.active)
  }
  if(typeof e?.bulk_upload !== 'undefined') {
    params.append('bulk_upload', e.bulk_upload)
  }
  params.append('offset', page + 1)
  params.append('limit', facilities.limit)

  return params
}


