import React, { useEffect, useState } from "react";

import makeStyles from "@mui/styles/makeStyles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Uploads from "./Uploads";

import SnackbarContext from "../lib/context/SnackbarContext";
import UserContext from "../lib/context/UserContext";
import AlertContext from "../lib/context/AlertContext";
import Alert from "@mui/material/Alert";
import * as yup from "yup";

import axios from "../lib/axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import RHFMultiSelect from "./RHFMultiSelect";
import { Autocomplete, TextField } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  hiddenInput: {
    display: "none",
  },
}));

export default () => {
  const classes = useStyles();
  const [chosenFile, setChosenFile] = React.useState(null);
  const [showAlert, setAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState("");
  const snackbarCtx = React.useContext(SnackbarContext);
  const [bulkUploadTrigger, setBulkUploadTrigger] = React.useState(0);
  const { currentUser } = React.useContext(UserContext);
  const [facility, setFacility] = useState(currentUser.facilities[0])
  const [facilityValue, setFacilityValue] = useState(currentUser.facilities[0].facility_id);
  const [facilityLabel, setFacilityLabel] = useState(currentUser.facilities[0].name);
  const alertCtx = React.useContext(AlertContext);

  const refreshBulkUploads = () => {
    setBulkUploadTrigger(bulkUploadTrigger + 1);
  };

  const submitForm = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const params = new URLSearchParams();
    params.append("facility_id", facility.facility_id)
    formData.append("name", chosenFile.name);
    formData.append("file", chosenFile);

    const reader = new FileReader();
    reader.readAsText(chosenFile);
    reader.onload = () => {
      const content = reader.result;
      const match = content.match(/\r?\n/g);
      if (match && match.length && match.length <= 250) {
        axios
          .post("/bulk_uploads", formData, {params: params})
          .then((response) => {
            if (response.data.status === "error") {
              setAlertMsg(response.data.message);
              setAlert(true);
            } else {
              setAlert(false);
              snackbarCtx.showSnackbar(response.data.message);
            }
            setChosenFile(null);
            refreshBulkUploads();
          })
          .catch((error) => {
            setAlertMsg(
              "Problem with uploading file, please contact administrator"
            );
            setAlert(true);
            setChosenFile(null);
          });
      } else {
        setAlert(false);
        snackbarCtx.showSnackbar(
          "File allowed to contain maximum of 250 records"
        );
        setChosenFile(null);
      }
    };
  };

  const [links, setLinks] = React.useState({
    bulk_upload_instructions: "",
    bulk_upload_template: "",
  });

  const changed = (event) => {
    try {
      setChosenFile(event.target.files[0]);
    } catch (e) {
      console.log("Problem saving selected file", e);
      setChosenFile(null);
    }
    event.target.value = "";
  };

  React.useEffect(() => {
    const relativeUri = process.env.RELATIVE_URI;
    setLinks({
      bulk_upload_instructions: relativeUri + `state-assets/files/Portal_Result_Template_Instructions.pdf`,
      bulk_upload_template: relativeUri + `state-assets/files/Portal_Result_Template.xlsx`,
    });
  }, [setLinks]);
  
  const handleChange = (event, value) => {
    if(value) {
      setFacility(value)
    }
  }

  return (
    <>
      {showAlert ? (
        <Alert
          className="mb-3 pre"
          onClose={() => setAlert(false)}
          severity="error"
        >
          {alertMsg}
        </Alert>
      ) : null}
      <Card variant="outlined" style={{ margin: "0px auto", maxWidth: "100%" }}>
        <UserContext.Consumer>
          {({ currentUser }) =>
            facility.bulk_upload ? (
              /* Has bulk upload permission */
              <Card variant="outlined">
                <CardContent>
                  <p className="m-0 card-header">Bulk upload</p>
                  <form className="pt-3" onSubmit={submitForm}>
                    <Grid container>
                      <Grid item xs={4}>
                      <Autocomplete
                        clearOnBlur
                        options={currentUser.facilities}
                        getOptionLabel={option => option.name}
                        defaultValue={facility}
                        onChange={handleChange}
                        renderInput={(params) => <TextField {...params} label="Facility" />}
                      />
                      </Grid>
                    </Grid>
                    <Grid container className="pt-3">
                      <Grid item xs={10}>
                        <input
                          accept=".csv"
                          onChange={changed}
                          className={classes.hiddenInput}
                          id="fileinput"
                          type="file"
                        />
                        <label htmlFor="fileinput">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                          >
                            Select file
                          </Button>
                          <span className="pl-3">{chosenFile?.name}</span>
                        </label>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit"
                          disabled={chosenFile === null}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                    <Grid
                      className="py-3"
                      container
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <p className="m-0 card-header">Instructions:</p>
                      <Typography variant="body1">
                        <a
                          rel="noreferrer"
                          href={links.bulk_upload_template}
                          target="_blank"
                          download
                        >
                          Download template
                        </a>{" "}
                        and follow the{" "}
                        <a
                          rel="noreferrer"
                          href={links.bulk_upload_instructions}
                          target="_blank"
                        >
                          instructions document
                        </a>{" "}
                        to fill columns in template.
                      </Typography>
                    </Grid>
                  </form>
                  {/* </Formik> */}
                </CardContent>
              </Card>
            ) : (
              /* No bulk upload permission */
              <CardContent>
                <Box style={{ textAlign: "center" }}>
                  <Typography variant="subtitle1">
                    Contact{" "}
                    <a href="mailto:EpiTraxAdmin@ks.gov">EpiTraxAdmin@ks.gov</a>{" "}
                    to request activation
                  </Typography>
                </Box>
              </CardContent>
            )
          }
        </UserContext.Consumer>
      </Card>
      <Uploads trigger={bulkUploadTrigger} />
    </>
  );
};
