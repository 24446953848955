import { combineReducers } from 'redux'

import bulkUploads from './BulkUpload/bulkupload.reducer'
import notificationReducer from './Notification/notification.reducer'
import patientSearchReducer from './PatientSearch/patientSearch.reducer';
import users from './UserManagement/userManagement.reducer';
import facilities from './FacilityManagement/facilityManagement.reducer';
import config from './Config/config.reducer';

const rootReducer = combineReducers({
  notifications: notificationReducer,
  bulkUploads: bulkUploads,
  patientSearch: patientSearchReducer,
  users: users,
  facilities: facilities,
  config: config
})

export default rootReducer
