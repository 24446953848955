import React, { useRef, useEffect, useState } from 'react'
import axios from '../lib/axios.js'

// TODO: Test production mode
// TODO: Add a notice that we're using reCaptcha. That's part of the Termas of
// Service for it. See https://www.google.com/recaptcha/admin/create#v3signup

import { pathname } from '../lib/misc'

import {
  Route,
  Link,
  useLocation,
  useNavigate,
  Routes
} from 'react-router-dom'

import makeStyles from '@mui/styles/makeStyles'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'

import BulkUpload from './BulkUpload'
import Dashboard from './Dashboard'
import ErrorSubmission from './ErrorSubmission.jsx'
import ForgotPassword from './ForgotPassword'
import PasswordReset from './PasswordReset'
import PortalFooter from './PortalFooter'
import PortalHeader from './PortalHeader'
import PortalSnackbar from './PortalSnackbar'
import RegisterUser from './RegisterUser'
import ShowCase from './ShowCase'
import LogOut from './LogOut'

import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import SnackbarContext from '../lib/context/SnackbarContext'
import UserContext from '../lib/context/UserContext'
import SourcesContext from '../lib/context/SourcesContext'
import { PATHS } from '../constants/uri.constants.js'
import UserManagement from './user_management/UserManagement.jsx'
import ShowUser from './user_management/ShowUser.jsx'
import FacilityManagement from './facility_management/FacilityManagement'
import RoleManagement from './role_management/RoleManagement'
import ShowFacility from './facility_management/ShowFacility.jsx'
import ShowRole from './role_management/ShowRole.jsx'
import ConditionManagement from './condition_management/ConditionManagement.jsx'
import ShowCondition from './condition_management/ShowCondition.jsx'
import { Menu, MenuItem } from '@mui/material'
import { checkPrivilege } from '../helpers/privilegesHelper.js'
import { PRIVILEGES } from '../constants/roles.constants.js'
import { useSelector } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const useStyles = makeStyles({
  bodyStyle: {
    width: '1140px',
    margin: '0px auto',
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden'
  },
  topButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    background: '#fafafa',
    padding: '12px'
  },
  linkButton: {
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    fontWeight: 'bold',
    fontSize: '1rem',
    /* optional */
    // fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
    /* input has OS specific font-family */
    color: '#069',
    textDecoration: 'underline',
    textTransform: 'none',
    cursor: 'pointer',
    height: '36px'
    // letterSpacing: '.0892857143em'
  },
  instructionRow: {
    // display: 'flex',
    // justifyContent: 'space-evenly',
    textAlign: 'center',
    width: '100%',
    marginTop: '15px'
  },
  menubar: {
    background: '#fafafa',
    padding: '1rem'
  },
  '@media print': {
    bodyStyle: {
      overflow: 'hidden',
      display: 'inline-block',
      margin: '25px'
    },
    instructionRow: {
      display: 'none'
    },
    menubar: {
      display: 'none'
    }
  },
  signInStuff: {
    display: 'flex',
    flexDirection: 'column'
  },
  mainArea: {
    paddingTop: '30px',
    height: '100%'
  },
  content: {
    flex: '1'
  },
  warningContent: {
    color: '#ff0000',
    fontWeight: 'bold'
  },
  redText: {
    color: '#f44336'
  },
  textArea: {
    flex: '1 1 auto',
    textAlign: 'center',
    fontSize: '16px',
    fontFamily: 'Roboto,sans-serif',
    marginTop: '16px',
    marginBottom: '32px',
    lineHeight: '1.5'
  }
})

const SignIn = ({ setOpen }) => {
  const classes = useStyles()
  return (
    <div className={classes.signInStuff}>
      <Button
        data-testid="signin"
        variant="contained"
        color="primary"
        style={{ height: '36px', padding: '0 16px', letterSpacing: '.0892857143em' }}
        onClick={() => { 
          window.location.href = pathname(true) + 'session/sign_in'
          console.log("inside") 
        }}>Sign In</Button>
      <Link to={ pathname(true) + 'forgot_password'} onClick={() => { setOpen(false) }} style={{ fontSize: '12px', padding: '5px' }}>Forgot Password</Link>
    </div>
  )
}

const Register = ({ setOpen }) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [registerDialog, setRegisterDialog] = React.useState(false);
  const dialogClose = (event, reason) => {
    // Don't close the dialog by clicking outside it
    if (reason !== 'backdropClick') { setOpen(false) }
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        style={{ height: '36px', padding: '0 16px', letterSpacing: '.0892857143em' }}
        onClick={() => { setRegisterDialog(true); navigate(pathname(true)) }}>Register new user</Button>
      <Dialog open={ registerDialog } onClose={dialogClose} >
        <DialogTitle id="alert-dialog-title">
            WARNING
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={classes.warningContent} component={'div'}>
                <div>
                  The Kansas Reportable Disease Portal is restricted to Public Health Departments, Laboratories and Healthcare Facilities.
                </div><br/>
                <div>It is not intended for use by individuals not associated with the above.</div><br/>
                <div>Thank you.</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ flex: '1 0 0' }} />
          <Button color="primary" variant="outlined" onClick={() => { setOpen(false); setRegisterDialog(false) }} >
            Reject
          </Button>
          <Button color="secondary" variant="outlined" onClick={() => { setOpen(true); setRegisterDialog(false) }}>
            Accept
          </Button>
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
      </Dialog>
    </div>
  )
}

const TestInfo = () => {
  const classes = useStyles()
  const [infoDialog, setInfoDialog] = React.useState(false)
  const config = useSelector(state => state.config.config);

  const atHome = (
    <div>
      <b>Information for At-Home Tests : </b>
      <Button
        variant="text"
        className={classes.linkButton}
        // style={{ height: '36px', padding: '0 16px', letterSpacing: '.0892857143em' }}
        onClick={() => {setInfoDialog(true)}}> At-Home Test Type Information
      </Button>
      <Dialog open={infoDialog} onClose={() => setInfoDialog(false)}>
        <DialogTitle style={{ color: '#000000', fontWeight: 'bold', textAlign: 'center' }} id="alert-dialog-title">
          At-Home Test Type Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: '#000000' }} component={'div'}>
                <div>
                  There are two methodologies for At-Home COVID PCR and At-Home Antigen tests. The distinction between the two
                  methodologies is very important for KDHE's case classifications.
                </div><br/>
                <div>In KORDS, please select the appropriate at-home test based on the methodology of the test.</div><br/>
                <ul>
                  <li>At-home self-administered PCR</li>
                  <li>At-home self-administered Antigen</li>
                  <li>At-home observed/administered PCR</li>
                  <li>At-home observed/administered Antigen</li>
                </ul><br/>

                <b>Self-Administered</b>
                <div>Self-Administered tests are where the test is performed (administered) entirely by the patient.</div><br/>

                <b>Observed/Administered</b>
                <div>Observed/Administered is where the test is either observed (proctored) or administered by a healthcare professional.</div><br/>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ flex: '1 0 0' }} />
            <Button
             variant="outlined"
             color="primary"
             onClick={() => {setInfoDialog(false)}} >
              CLOSE
            </Button>
          <div style={{ flex: '1 0 0' }} />
        </DialogActions>
      </Dialog>
    </div>
  )

  return (
    (config.instructions.atHomeTest && atHome) || null
  )
}

const initSources = {
  clinicians: [],
  conditions: [],
  counties: [],
  ethnicities: [],
  facilities: [],
  facilityVisitTypes: [],
  gender: [],
  labs: [],
  labTypes: [],
  races: [],
  roles: [],
  specimenSources: [],
  states: [],
  telephoneTypes: [],
  testResultValues: [],
  testTypes: [],
  sourcesLoaded: false
}

export default function App ( testUser ) {
  // const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [recaptcha, setRecaptcha] = React.useState('recaptcha token')
  const [printRef, setprintRef] = React.useState(useRef())
  const sitekey = process.env.RECAPTCHA_SITE_KEY
  const [snackbar, setSnackbar] = React.useState({ message: '', shown: false })
  const snackbarValue = {
    showSnackbar: m => setSnackbar({ message: m, shown: true }),
    hideSnackbar: () => setSnackbar({ message: '', shown: false }),
    ...snackbar
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = window.document.getElementById(id)

      if (!isScriptExist) {
        const script = window.document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        window.document.head.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }
    // load the script by passing the URL
    loadScriptByURL('recaptcha-key', `https://www.google.com/recaptcha/api.js?render=${sitekey}`, function () {
      console.log('Script loaded!')
    })
  }, [])

  const [open, setOpen] = React.useState(false)

  let registerForm
  if (open) {
    registerForm = (
      <>
       <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
          <RegisterUser setOpen={setOpen} open={open} />
        </GoogleReCaptchaProvider>
      </>
    )
  }

  const [user, setUser] = React.useState({
    currentUser: testUser?.testUser?.currentUser ? testUser?.testUser?.currentUser : {},
    checked: testUser?.testUser?.checked ? testUser?.testUser?.checked : false,
    loggedIn: testUser?.testUser?.loggedIn ? testUser?.testUser?.loggedIn : false,
    loggedOut: testUser?.testUser?.loggedOut ? testUser?.testUser?.loggedOut : false,
  })

  const userValue = {
    login (u) {
      setUser({
        currentUser: u,
        checked: true,
        loggedIn: true,
        loggedOut: false
      })
    },
    logout () {
      setUser({
        currentUser: null,
        checked: true,
        loggedIn: false,
        loggedOut: true
      })
    },
    ...user
  }

  const [sources, setSources] = React.useState({ ...initSources })
  const sourcesValue = {
    setClinicians: c => setSources({ ...sources, clinicians: c }),
    reset: () => setSources(initSources),
    ...sources
  }

  const config = useSelector(state => state.config.config);
  const state = process.env.DEFAULT_STATE

  const userGuide = process.env.RELATIVE_URI + `state-assets/files/Portal_User_Guide.pdf`
  // const helpInfo = process.env.DOMAIN_URL + process.env.RELATIVE_URI + '/files/At-Home Test Info for KORDS.docx'

  React.useEffect(() => {
    console.log("inside")
    axios.get('/notifications/me.json').then(resp => {
      console.log(resp)
      userValue.login(resp.data)
      getSources()
    }).catch(err => {
      userValue.logout()
      console.log(err)
    })
  }, [setUser])

  const getSources = () => {
    axios.get('/notifications/source').then(resp => {
      setSources({
        clinicians: sources.clinicians,
        conditions: resp.data.conditions,
        counties: resp.data.counties,
        ethnicities: resp.data.ethnicities,
        facilities: resp.data.facilities,
        facilityVisitTypes: resp.data.facility_visit_types,
        gender: resp.data.gender,
        labs: resp.data.labs,
        labTypes: resp.data.lab_types,
        races: resp.data.races,
        roles: resp.data.roles,
        specimenSources: resp.data.specimen_sources,
        states: resp.data.states,
        telephoneTypes: resp.data.telephone_types,
        testResultValues: resp.data.test_results,
        testTypes: resp.data.test_types,
        sourcesLoaded: true
      })
    })
  }

  const childProps = { printRef, getSources };
  const newCaseChildProps = { ...childProps, new: true };
  const showCaseChildProps = { ...childProps, new: false };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const paths = window?.location?.pathname?.includes('bulkupload') || window?.location?.pathname?.includes('newcase')
  return (
    <SnackbarContext.Provider value={snackbarValue}>
    <UserContext.Provider value={userValue}>
    <SourcesContext.Provider value={sourcesValue}>
      <div className={classes.bodyStyle} ref={printRef}>
        <PortalHeader />
        <PortalSnackbar />
        { (userValue.loggedOut) && (
          <div>
            <div>
              {
                config.header.headerTitle &&
                <p className='header-title'>{config.header.headerTitle}</p>
              }

              <div className={classes.topButtons}>
                <SignIn setOpen={setOpen}/>
                <Register setOpen={setOpen} open={open} setRecaptcha={setRecaptcha} recaptcha={recaptcha}/>
              </div>
            </div>
            {registerForm}
          </div>
        )}
        <div className={classes.content}>
          {
            userValue.loggedIn && (
              <>
                {paths && (
                    <div className={classes.textArea}>
                      <strong className={classes.redText}>**** New <u>COVID Test Types</u> - <u>At-home self-administered</u> and <u>At-home observed/administered</u> are available ****</strong>
                      <br />
                      <strong><u>BULK UPLOAD VALIDATION TOOL</u> - Files which do not comply with the standard values will not be uploaded. Error message given with necessary change(s)</strong>
                    </div>
                )}
                <div className={classes.menubar} data-testid="menubar">
                  <Grid container justifyContent="space-between">
                    <Grid container item spacing={2} xs={9}>
                      <Grid item>
                        <Button variant="contained"
                          color={location.pathname === PATHS.DASHBOARD ? 'secondary' : 'primary'}
                          onClick={() => navigate(pathname(true))}>Dashboard</Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained"
                          color={location.pathname === PATHS.NEW_REPORT ? 'secondary' : 'primary'}
                          onClick={() => navigate(pathname(true) + 'newcase')}>New Report</Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained"
                          color={location.pathname === PATHS.BULK_UPLOAD ? 'secondary' : 'primary'}
                          onClick={() => navigate(pathname(true) + 'bulkupload')}>Bulk Upload</Button>
                      </Grid>
                      <Grid item>
                      {
                        !!(checkPrivilege(userValue.currentUser, PRIVILEGES.MANAGE_FACILITY) || checkPrivilege(userValue.currentUser, PRIVILEGES.MANAGE_FACILITIES)) &&
                        <Button
                        id="admin-button"
                        color='primary'
                        variant='contained'
                        aria-controls={openMenu ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? 'true' : undefined}
                        onClick={handleClick}
                        >
                          Admin
                        </Button>
                      }
                      </Grid>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        color='primary'
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        {(checkPrivilege(userValue.currentUser, PRIVILEGES.MANAGE_USERS) || checkPrivilege(userValue.currentUser, PRIVILEGES.MANAGE_FACILITY_USERS)) && 
                            <MenuItem onClick={() => {navigate(pathname(true) + 'users'); handleClose();} }>Users</MenuItem>}
                        {(checkPrivilege(userValue.currentUser, PRIVILEGES.VIEW_USER_FACILITIES ) || checkPrivilege(userValue.currentUser, PRIVILEGES.MANAGE_FACILITIES))
                          && <MenuItem onClick={() => {navigate(pathname(true) + 'facilities'); handleClose();} }>Facilities</MenuItem>}
                        {checkPrivilege(userValue.currentUser, PRIVILEGES.MANAGE_ROLES) && <MenuItem onClick={() => {navigate(pathname(true) + 'roles'); handleClose();} }>Roles</MenuItem>}
                        {checkPrivilege(userValue.currentUser, PRIVILEGES.MANAGE_CONDITIONS) && <MenuItem onClick={() => {navigate(pathname(true) + 'conditions'); handleClose();} }>Conditions</MenuItem>}
                      </Menu>
                    </Grid>
                    <Grid container item spacing={2} alignItems="center" justifyContent='flex-end' xs={3}>
                      <Grid item>
                        <div className="d-flex flex-column align-center">
                          <p className='font-bold m-0'>{ userValue?.currentUser?.first_name } { userValue?.currentUser?.last_name}</p>
                        </div>
                      </Grid>
                      <Grid item>
                        <LogOut />
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </>
            )
          }
          <div className={classes.instructionRow}>
            <b>Instructions to use Portal : <a rel="noreferrer" href={userGuide} target="_blank">Online Reporting Portal User Guide</a></b>
            <TestInfo/>
            {
              userValue.loggedIn && 
              <>
                <div>If an error was made to any data in the original submission through the individual entry or bulk entry to the portal, please use the</div>
                <Button
                  variant="text"
                  className={classes.linkButton}
                  onClick={() => { navigate(pathname(true) + 'error_submission') }}> Error Submission Form
                </Button>
              </>
            }
          </div>
          <Grid container>
            <Grid item xs={12} className={classes.mainArea}>
              {
                userValue.loggedIn
                  ? (
                      <Routes>
                        <Route path={pathname(true) + 'newcase'} element={<ShowCase {...newCaseChildProps} />} />
                        <Route path={pathname(true) + 'case/:case'} element={<ShowCase {...showCaseChildProps}/>} />
                        <Route path={pathname(true) + 'bulkupload'} element={<BulkUpload />} />
                        <Route path={pathname(true)} exact element={<Dashboard />} />
                        <Route path={pathname(true) + 'users'} element={<UserManagement />} />
                        <Route path={pathname(true) + 'user/:user'} element={<ShowUser />} />
                        <Route path={pathname(true) + 'facilities'} exact element={<FacilityManagement/>} />
                        <Route path={pathname(true) + 'roles'} exact element={<RoleManagement/>} />
                        <Route path={pathname(true) + 'facility/:facility'} element={<ShowFacility/>} />
                        <Route path={pathname(true) + 'role/:role'} element={<ShowRole />} />
                        <Route path={pathname(true) + 'conditions'} exact element={<ConditionManagement/>} />
                        <Route path={pathname(true) + 'condition/:condition'} element={<ShowCondition />} />
                        <Route path={pathname(true) + 'error_submission'} element={<ErrorSubmission />} />
                      </Routes>
                    )
                  : (
                      <Routes>
                        <Route path={ pathname(true) + 'forgot_password'} Component={ForgotPassword} />
                        <Route path={ pathname(true) + 'password_reset/:token'} Component={PasswordReset}/>
                      </Routes>
                    )
              }
            </Grid>
          </Grid>
        </div>
        <PortalFooter />
      </div>
    </SourcesContext.Provider>
    </UserContext.Provider>
    </SnackbarContext.Provider>
  )
}
