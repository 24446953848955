import React from 'react'
import { useSelector } from 'react-redux';

export default function PortalFooter () {
  const footer =useSelector(state => state.config.config.footer);
    return (
      <div className='d-flex align-center justify-center font-bold footerContainer mt-3'>
        <div>
          &copy; { new Date().getFullYear() } — {footer.footerText}
        </div>
      </div>
    )
}
