import React from 'react'
import { useForm }  from 'react-hook-form'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import UserContext from '../lib/context/UserContext'
import SnackbarContext from '../lib/context/SnackbarContext'
import moment from 'moment'
import Checkbox from '@mui/material/Checkbox';

import axios from '../lib/axios.js'
import { formatDate, pathname } from '../lib/misc'
import makeStyles from '@mui/styles/makeStyles';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux'

import { setBulkUploadPage, setBulkUploadSearchParams } from '../store/BulkUpload/bulkupload.actions';
import { getBulkUploads } from '../helpers/bulkUploadHelper'

import { setCount, setNotifications, setPage, setSearchParams } from '../store/Notification/notification.actions'
import { constructNotificationSearchParams } from '../helpers/notificationHelper'
import { PRIVILEGES } from '../constants/roles.constants'
import { checkPrivilege } from '../helpers/privilegesHelper'

const useStyles = makeStyles({
    fullWidthCentered: {
        width: '100%',
        textAlign: 'center'
    },
    tableRow: {
        padding: '0px'
    },
    table: {
      minWidth: 200,
    },
    submitNote: {
        textAlign: 'center',
        fontFamily: 'Roboto,sans-serif',
        color: 'rgba(0,0,0,.87)'
    }
});

export default function ErrorSubmission (testUser) {
    const snackbarCtx = React.useContext(SnackbarContext)
    // if (Object.keys(testUser).length !== 0) {console.log("a")}
    const { currentUser } = Object.keys(testUser).length !== 0 ? testUser : React.useContext(UserContext)
    const classes = useStyles();
    const navigate = useNavigate()
    const params = window.location.pathname.replace( /^\D+/g, '')
    const [selectedRow, setSelectedRow] = React.useState(-1)
    const [readOnly, setReadOnly] = React.useState(false)
    const [relativeUri, setRelativeUri] = React.useState('/')
    const bulkUploads = useSelector(state => state.bulkUploads)
    const notifications = useSelector(state => state.notifications)
    const dispatch = useDispatch();

    const today = new Date()
    const monthAgo = new Date((new Date()).setMonth(today.getMonth() - 1))
    const fromDate = monthAgo.toISOString().substr(0, 10);
    const toDate = today.toISOString().substr(0, 10)

    const { register, handleSubmit, watch, setValue, getValues, formState: { errors } } = useForm({ 
        mode: 'all', 
        defaultValues: {
            facility: currentUser.facilities[0].name,
            submitter: currentUser.first_name + " " + currentUser.last_name,
            email: currentUser.username,
            case: null
        }
    })
  
    const updateBulkUploads = (params) => {
        dispatch(setBulkUploadSearchParams(params))
        dispatch(setBulkUploadPage(0))
        getBulkUploads(params)
    }

    const updateNotifications = (e) => {
        dispatch(setSearchParams(e))
        const params = constructNotificationSearchParams(e)
        axios.get('/notifications/search.json', { params }).then((resp) => {
            dispatch(setNotifications(resp.data.notifications))
            dispatch(setCount(resp.data.total_entries))
            dispatch(setPage(0))
        })
    }

    const selectedFacilityDef = {id: currentUser?.facility_ids[0], name: currentUser?.facilities[0]?.name}
    const resetSearch = () => {
        setReadOnly(false)
        const case_id = window.location.pathname.replace( /^\D+/g, '')
        if (case_id) {
            axios.get('/errors/'+ case_id).then(resp => {
                setReadOnly(true)
                setValue('facility', resp.data.data.facility )
                setValue('email', resp.data.data.submitter.username)
                setValue('submitter', resp.data.data.submitter.first_name + " " + resp.data.data.submitter.last_name)
                setValue('type',resp.data.data.type )
                setValue('area_code', resp.data.data.area_code)
                setValue('phone_number', resp.data.data.phone_number)
                setValue('date', resp.data.data.error.submission_date.slice(0,10))
                setValue('case', resp.data.data.case)
                setValue('comment', resp.data.data.comment)
                setValue('case_info', resp.data.data.case_info)

                if (resp.data.data.type === 'Bulk') {    
                    let bulkParams = {
                        fromDate: resp.data.data.error.submission_date.slice(0,10),
                        toDate: resp.data.data.error.submission_date.slice(0,10),
                        selectedFacilities: selectedFacilityDef
                    }
                    updateBulkUploads(bulkParams)
                }
                else {
                    let notifParams = {
                        fromDate: resp.data.data.error.submission_date.slice(0,10),
                        toDate: resp.data.data.error.submission_date.slice(0,10)
                    }
                    updateNotifications(notifParams)

                }
            })
        }

        let bulkParams = {
            fromDate: readOnly ? getValues('date') : fromDate,
            toDate: readOnly ? getValues('date') : toDate,
            selectedFacilities: selectedFacilityDef
        }
        updateBulkUploads(bulkParams)
        

        let notifParams = {
            fromDate: readOnly ? getValues('date') : fromDate,
            toDate: readOnly ? getValues('date') : toDate,
        }
        updateNotifications(notifParams)
    }

    React.useEffect(() => {
        resetSearch()
    }, [])

    React.useEffect(() => {
      setRelativeUri(process.env.DOMAIN_URL + process.env.RELATIVE_URI)
      if (!params) {
        resetSearch()
        setValue('type', null )
        setValue('area_code', null)
        setValue('phone_number', null)
        setValue('date', null)
        setValue('case', null)
        setValue('comment', '')
        setSelectedRow(-1)
      }
      else {
        resetSearch()
      }
    }, [setRelativeUri, params])
  

    const doHandleSubmit = function (values) {
        axios.post('/errors/create', values).then(resp => {
            snackbarCtx.showSnackbar('Your error case has been logged and email sent')
            navigate(pathname(true) + 'error_submission/' + resp.data.error_id)
            setReadOnly(true)
        }).catch(err => {
            if (err) {
                console.log(err)
                snackbarCtx.showSnackbar('Error with inputting case to database')
            }
        })
    }

    const fileLink = v => {
        if (checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITIES) || checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITY) || currentUser.id === v.user_id) {
          return (
            <a target="_blank" rel="noreferrer" href={relativeUri + '/bulk_uploads/' + v.id} download>
              {v.filename}
            </a>
          )
        } else {
          return v.filename
        }
    }

    const linkToCase = (params) => {
        return (
          <Link to={pathname(true) + 'case/' + params.id}>{ params.patient_full_name}</Link>
        )
    }

    const emailLink = v => (
        <a href={`mailto:${v.username}`}>{v.username}</a>
    )

    const testResultCell = (params) => {
        const onClick = () => {
        navigate(pathname(true) + 'case/' + params.id + '-edit')
        }
        if (params.test_result_name) {
        return (params.test_result_name)
        } else if (params.specimen_sent_at) {
        return (<Button variant="contained" color="primary" onClick={onClick}>Add</Button>)
        } else {
        return ('No Specimen Sent')
        }
    }

    const columns = [
        { field: 'patient_full_name', headerName: 'Name', renderCell: linkToCase, width: '15%', sortable: false },
        { field: 'patient_dob', headerName: 'DOB', valueFormatter: formatDate, width: '10%', sortable: false },
        { field: 'condition_name', headerName: 'Condition', width: 270, width: '20%', sortable: false },
        { field: 'test_result_name', headerName: 'Result', renderCell: testResultCell, width: '10%', sortable: false, width: 140 },
        { field: 'facility_name', headerName: 'Facility Name', flex: true, sortable: false,  width: '15%' },
        { field: 'county_name', headerName: 'County', sortable: false, width: '10%' },
        { field: 'specimen_sent_at', headerName: 'Collection Date', valueFormatter: formatDate, sortable: false, width: '10%' },
        { field: 'created_at', headerName: 'Created', valueFormatter: formatDate, sortable: false, width: '10%' }
    ]
    
    const bulkColumns = [
        { field: 'created_at', headerName: 'Date', flex: true, valueFormatter: formatDate, sortable: false, width: '15%' },
        { field: 'filename', headerName: 'File Name', flex: true, renderCell: fileLink, sortable: false, width: '35%' },
        { field: 'user_full_name', headerName: 'Created User', flex: true, valueGetter: v => `${v.first_name} ${v.last_name}`, sortable: false, width: '15%' },
        { field: 'username', headerName: 'Email', flex: true, renderCell: emailLink, sortable: false, width: '20%' },
        { field: 'facility_name', headerName: 'Facility', flex: true, sortable: false, width: '15%' }
    ]

    return(    
        <>
        { readOnly && (
            <>
            <div className={classes.submitNote}>
                <div>CASE SUBMITTED</div> 
                <div>Email Sent To: KDHE.EpiTraxAdmin@ks.gov</div> 
            </div><br/><br/>
            </>
        )}
        <Card variant="outlined">
            <CardContent>
                <p className='m-0 card-header'>SUBMITTER INFORMATION</p>
                <Grid className='py-3' container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            className="w-100"  
                            name="facility" 
                            inputProps={{"data-testid": "facility"}}
                            InputLabelProps={{ shrink: watch('facility') !== '' }}
                            variant="standard"
                            label="Facility Name" 
                            {...register('facility')}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            className="w-100"  
                            name="submitter" 
                            inputProps={{"data-testid": "submitter"}}
                            InputLabelProps={{ shrink: watch('submitter') !== '' }}
                            variant="standard"
                            label="Submitter" 
                            {...register('submitter')}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            className="w-100"  
                            name="email" 
                            inputProps={{"data-testid": "email"}}
                            InputLabelProps={{ shrink: watch('email') !== '' }}
                            variant="standard"
                            label="Submitter Email" 
                            {...register('email')}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField 
                            className="w-100"
                            label="Area Code" variant="standard"    
                            InputLabelProps={{ shrink: watch('area_code') !== '' }}        
                            {...register ( 'area_code' , { 
                                pattern: { value: /^\d\d\d$/, message: 'Must be three digits' }
                            })}
                            disabled={readOnly}
                            error={!!errors.area_code }
                            helperText={ errors.area_code && errors.area_code.message }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            className="w-100"
                            label="Phone Number" variant="standard"
                            InputLabelProps={{ shrink: watch('phone_number') !== '' }}  
                            {...register( 'phone_number', {
                                pattern: { value: /^\d{7}$/, message: 'Must be seven digits' },
                            })}
                            disabled={readOnly}
                            error={!!errors.phone_number }
                            helperText={ errors.phone_number && errors.phone_number.message }
                        />
                    </Grid>
                    <Grid item xs={4} >
                        <p style={{ fontFamily: 'Roboto,sans-serif', fontSize: '1rem', fontWeight: '400', color: errors.type && '#d32f2f' }}>Select the type of orginial submission: *</p>
                        <label style={{marginLeft: '15px', fontFamily: 'Roboto,sans-serif', fontSize: '1rem', fontWeight: '400'}}> 
                            <input
                                {...register("type", {
                                        required: { value: true, message: 'Required' },
                                    }
                                )}
                                type="radio"
                                name="type"
                                value="Individual"
                                disabled={readOnly}
                            />
                            Individual Entry
                        </label>
                        <label style={{marginLeft: '10px', fontFamily: 'Roboto,sans-serif',  fontSize: '1rem', fontWeight: '400'}}>
                            <input
                                {...register("type", {
                                        required: { value: true, message: 'Required' },
                                    }
                                )}
                                type="radio"
                                name="type"
                                value="Bulk"
                                disabled={readOnly}
                            />
                            Bulk Entry
                        </label>
                        <br/><br/>
                        {errors.type && <small style={{ color: '#d32f2f', fontFamily: 'Roboto,sans-serif', fontSize: '0.75rem', fontWeight: '400'}}>Required</small>}
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            name="date" type="date" pattern="\d{4}-\d{2}-\d{2}" 
                            className="w-100" variant="standard"
                            label="Date of Original Submission *" 
                            inputProps={{"data-testid": "date"}}
                            InputLabelProps={{ shrink: true }}
                            {...register( 'date', {
                                    required: { value: true, message: 'Required' },
                                    validate: value => moment().diff(moment(value)) >= 0 || 'Cannot be a future date'   
                                } 
                            )}
                            onChange={e => {
                                if (!readOnly) {
                                    updateBulkUploads({...bulkUploads.params, fromDate: e.target.value, toDate: e.target.value })
                                    updateNotifications({...notifications.params, fromDate: e.target.value, toDate: e.target.value })
                                }
                            }}
                            disabled={readOnly}
                            error={!!errors.date }
                            helperText={ errors.date && errors.date.message }
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
        <Card className="mt-3" variant="outlined" style={{ margin: '0px auto', maxWidth: '100%', padding: '16px' }}>
            <p className='m-0 card-header'>Entry Search </p>
            
           { watch("type") ? <CardContent>
                <div className='mt-3'>
                    <p className='m-0 card-header'> Please Select Case *</p>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <div className='bulkuploadsTable' style={{ flexGrow: 1 }}>
                        <TableContainer>
                            <Table style={{ tableLayout: "auto" }} className={classes.table} size='small' stickyHeader> 
                            <colgroup>
                                { watch('type') == 'Individual' && 
                                    columns.map((column, index) => (
                                        <col key={index} width={column.width} />
                                    ))
                                }
                                { watch('type') == 'Bulk' && 
                                    bulkColumns.map((column, index) => (
                                        <col key={index} width={column.width} />
                                    ))
                                }
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                {watch('type') == 'Individual' && 
                                    columns.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            align='left'
                                            style={{ width: `${column.width}`}}
                                        >
                                            {column.headerName}
                                        </TableCell>
                                    ))
                                }
                                {watch('type') == 'Bulk' && 
                                    bulkColumns.map((column, index) => (
                                        <TableCell
                                            key={index}
                                            align='left'
                                            style={{ width: `${column.width}`}}
                                        >
                                            {column.headerName}
                                        </TableCell>
                                    ))
                                }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { watch('type') === 'Individual' ? 
                                    ( readOnly ? getValues('case_info') : notifications.notifications)?.map((uploadItem, rowIndex) => {
                                        return (
                                        <TableRow 
                                            key={rowIndex} 
                                            // role="checkbox"
                                            style={{backgroundColor: selectedRow === rowIndex ? "#66BB6A" : ""}}
                                            hover={!readOnly}
                                            onClick={() => { 
                                                if (!readOnly) {
                                                    setValue('case_link', window.location.href + '/')
                                                    setValue('date', uploadItem.created_at.slice(0,10))
                                                    setValue('case', uploadItem.id)
                                                    setSelectedRow(rowIndex)
                                                }
                                            }}
                                        >
                                            {
                                                columns.map((column, index) => {
                                                    return (
                                                    column?.renderCell ? (
                                                        <TableCell align='left' style={{ padding: '0px'}} key={index}>
                                                            {column?.headerName === 'Name' && <Checkbox
                                                                checked={selectedRow === rowIndex || readOnly}
                                                            />}
                                                            {column.renderCell(uploadItem)}
                                                        </TableCell>
                                                    ) : column?.valueFormatter ? (
                                                        <TableCell align='left' style={{ padding: '0px'}} key={index}>
                                                            { formatDate(uploadItem?.[column?.field]) }
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell align='left' style={{ padding: '0px'}} key={index}>
                                                            {uploadItem[column.field]}
                                                        </TableCell>
                                                    )
                                                    )
                                                })
                                            }
                                        </TableRow>
                                        )
                                    }) : ( readOnly ? getValues('case_info') : bulkUploads.uploads)?.map((uploadItem, rowIndex) => {
                                        return (
                                        <TableRow 
                                            key={rowIndex}
                                            style={{backgroundColor: (selectedRow === rowIndex || readOnly) ? "#66BB6A" : ""}}
                                            onClick={() => { 
                                                if (!readOnly) {
                                                    setValue('case_link', window.location.href + '/')
                                                    setValue('case', uploadItem.id)
                                                    setValue('date', uploadItem.created_at.slice(0,10))
                                                    setSelectedRow(rowIndex)
                                                }
                                            }}
                                            hover={!readOnly}
                                        >
                                            {
                                            bulkColumns.map((column, index) => {
                                                return (
                                                    column?.renderCell ? (
                                                        <TableCell align='left' style={{ padding: '0px'}} key={index}>   
                                                            {column.renderCell(uploadItem)}
                                                        </TableCell>
                                                    ) : column?.valueFormatter ? (
                                                        <TableCell align='left' style={{ padding: '0px'}} key={index}>
                                                        {column?.headerName === 'Date' && <Checkbox
                                                            checked={selectedRow === rowIndex || readOnly}
                                                        />} 
                                                            { formatDate(uploadItem?.[column?.field]) }
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell align='left' style={{ padding: '0px'}} key={index}>
                                                            {uploadItem[column.field]}
                                                        </TableCell>
                                                )
                                                )
                                            })
                                            }
                                        </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    </div>
                </div>
            </CardContent> : 
            <div style={{ display: 'flex', placeContent: 'center', alignItems: 'center', height: '100px' }}>
                <p className='mb-2 card-subtitle text-muted'>Please Fill Case Type & Date of Submission</p>
            </div>
            
            }
        </Card>
        <Card variant="outlined">
            <form onSubmit={handleSubmit(doHandleSubmit)}>
            <CardContent>
                <p className='m-0 card-header'>REQUESTED CORRECTION *</p>
                <Grid className='py-3' container spacing={2}>
                    <Grid item xs={11}>
                        <TextField
                            name="comment" multiline={true} 
                            rows={7} fullWidth
                            variant="standard"
                            disabled={readOnly}
                            InputLabelProps={{ shrink: watch('comment') !== '' }}
                            label="Please detail both the incorrect data and the requested data correction"
                            {...register( 'comment' )}
                        />
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <div style={{ flex: '1 0 0' }} />
                {!readOnly && <Button color="secondary" variant="outlined" type="submit">SUBMIT</Button>}
            </CardActions>
            </form>
        </Card>
        </>
    
    )
}