import React from 'react'

export default React.createContext({
  currentUser: {},
  checked: false,
  loggedIn: false,
  loggedOut: false,
  login: u => {},
  logout: () => {}
})
