import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { blue } from '@mui/material/colors'
import { Provider } from 'react-redux'
import config from '../../../config.json';
import { createRoot } from 'react-dom/client';

import App from '../components/App.jsx'
import store from '../store/store'

import './index.scss'

const theme = createTheme({
  palette: {
    primary: {
      main: config.theme.primary
    },
    secondary: {
      main: config.theme.secondary
    },
    info: {
      main: blue[500]
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: 'white'
        },
        containedSecondary: {
          color: 'white'
        }
      }
    }
  }
})

document.addEventListener('DOMContentLoaded', () => {
  const root = createRoot(document.body.appendChild(document.createElement('div')))
  root.render(
    (
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Router>
              <App />
            </Router>
          </ThemeProvider>
        </StyledEngineProvider>
      </Provider>
    ) 
  ) 
})
