import React from "react";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { AddBox, Delete } from "@mui/icons-material";

import VirtualizedAutocomplete from "./VirtualizedAutocomplete";
import RHFMultiSelect from "./RHFMultiSelect";
import SourcesContext from "../lib/context/SourcesContext";

export default function TestResultsInformation(props) {
  const numOfTestResults = useWatch({
    control: props.control,
    name: "lab_test.lab_test_results",
  });
  let count = 1;
  if (props.caseId) {
    count = numOfTestResults?.length;
  }
  const [testResultCount, setTestResultCount] = React.useState(count);
  const { testTypes, testResultValues, labs } =
    React.useContext(SourcesContext);

  function findUnique(labs) {
    let seen = {};
    return labs.filter((element) => {
      return seen.hasOwnProperty(element.name)
        ? false
        : (seen[element.name] = true);
    });
  }

  const addTestResult = () => {
    setTestResultCount(testResultCount + 1);
  };

  const deleteTestResult = (res) => {
    setTestResultCount(testResultCount - 1);
  };

  function findUnique(labs) {
    let seen = {};
    return labs.filter((element) => {
      return seen.hasOwnProperty(element.name)
        ? false
        : (seen[element.name] = true);
    });
  }

  const uniqueLabs = findUnique(labs);

  let testResultsSection = null;
  // if ((testResultsReceived && {}.hasOwnProperty.call(testResultsReceived, 'value') && testResultsReceived.value) || (testResultsReceived === "Yes")) {
  //   /*
  //    * TODO: Validate that test type and result are specified
  //    */

  const trHeader = (
    <>
      <Grid className="pt-3" container spacing={2}>
        <Grid item xs={8}>
          {
            isNaN(props.caseId) ? (
              <VirtualizedAutocomplete
                options={labs.map((l) => l.name)}
                label="Testing Lab *"
                freeSolo={true}
                error={!!props.errors.lab_test?.lab}
                fullWidth
                readOnly={!!props.caseId}
                control={props.control}
                helperText={
                  props.errors.lab_test?.lab &&
                  props.errors.lab_test?.lab.message
                }
                defaultValue={props.getValues().lab_test?.lab?.name}
                register={props.register}
                fieldName="lab_test.lab"
                registerOpts={{
                  required: { value: true, message: "Required" },
                  maxLength: {
                    value: 100,
                    message: "Must be less than 100 characters",
                  },
              }}
            />
            ) : (
              <FormControl fullWidth>
                  <TextField
                    name="lab_test.lab" {...props.register('lab_test.lab.name')}
                    inputProps={{ readOnly: true }} label="Testing Lab *"
                    InputLabelProps={{ shrink: true }} variant="standard"
                  />
              </FormControl>
            ) 
          }
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <TextField
              name="lab_test.test_date"
              className="w-100"
              inputProps={{ readOnly: !!props.caseId }}
              InputLabelProps={{ shrink: true }}
              type="date"
              pattern="\d{4}-\d{2}-\d{2}"
              label="Tested Date *"
              variant="standard"
              {...props.register("lab_test.test_date")}
              error={!!props.errors.lab_test?.test_date}
              helperText={
                props.errors.lab_test?.test_date &&
                props.errors.lab_test?.test_date.message
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );

  const trFields = [];
  const gos = (a, b) => {
    return true;
    // (a === '' && (b instanceof Array && b.length === 0)) ||
    // (a.id && b.id && a.id === b.id && a.name === b.name)
  };

  for (let ix = 0; ix < testResultCount; ix++) {
    trFields.push(
      <Grid
        className="pt-3"
        container
        spacing={2}
        key={`test-result-number-${ix}`}
      >
        <Grid item xs={5}>
          <RHFMultiSelect
            name={`lab_test.lab_test_results[${ix}].test_type_id`}
            fullWidth
            register={props.register}
            readOnly={!!props.caseId}
            addBlankOption={true}
            label="Test Type *"
            control={props.control}
            options={testTypes}
            //defaultValue={null}
            //objGOS={true}
            getOptionSelected={gos}
            error={
              !!props.errors.lab_test?.lab_test_results?.[ix]?.test_type_id
            }
            helperText={
              props.errors.lab_test?.lab_test_results?.[ix]?.test_type_id &&
              props.errors.lab_test?.lab_test_results?.[ix]?.test_type_id
                .message
            }
          />
        </Grid>
        <Grid item xs={5}>
          <RHFMultiSelect
            name={`lab_test.lab_test_results[${ix}].test_result_id`}
            fullWidth
            register={props.register}
            readOnly={!!props.caseId}
            addBlankOption={true}
            label="Test Result *"
            control={props.control}
            options={testResultValues}
            //defaultValue={null}
            //objGOS={true}
            getOptionSelected={gos}
            error={
              !!props.errors.lab_test?.lab_test_results?.[ix]?.test_result_id
            }
            helperText={
              props.errors.lab_test?.lab_test_results?.[ix]?.test_result_id &&
              props.errors.lab_test?.lab_test_results?.[ix]?.test_result_id
                .message
            }
          />
        </Grid>
        {ix > 0 && !props.caseId ? (
          <Grid item xs={2}>
            <IconButton onClick={(e) => deleteTestResult(ix)} size="large">
              <Delete />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    );
  }

  testResultsSection = (
    <>
      {trHeader}
      {trFields}
      {!props.caseId ? (
        <Grid className="pt-3" container spacing={2}>
          <Grid item xs={12}>
            {testResultCount < 3 && (
              <Button
                startIcon={<AddBox color="primary" />}
                onClick={addTestResult}
              >
                Add additional test result for this specimen
              </Button>
            )}
          </Grid>
        </Grid>
      ) : null}
    </>
  );

  return (
    <Card className="mt-3" variant="outlined">
      <CardContent>
        <p className="m-0 card-header">TESTING RESULTS</p>
        {testResultsSection}
        <Grid className="pt-3" container spacing={2}>
          <Grid item xs={8}>
            <TextField
              name="comment"
              multiline={true}
              rows={7}
              fullWidth
              inputProps={{ readOnly: props.readonly }}
              variant="standard"
              InputLabelProps={{ shrink: props.watch("comment") !== "" }}
              label="Comment (please include symptoms and specimen routing information)"
              {...props.register("comment")}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
TestResultsInformation.propTypes = {
  watch: PropTypes.func,
  readonly: PropTypes.bool,
  control: PropTypes.object,
  register: PropTypes.func,
  caseId: PropTypes.string,
  errors: PropTypes.shape({
    is_test_resulted: PropTypes.object,
    lab_test: PropTypes.shape({
      lab: PropTypes.object,
      test_date: PropTypes.object,
      lab_test_results: PropTypes.arrayOf(
        PropTypes.shape({
          test_type_id: PropTypes.object,
          test_result_id: PropTypes.object,
        })
      ),
    }),
  }),
};
