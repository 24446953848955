import { Button, Card, CardContent, Grid, TextField } from '@mui/material'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import LoadingButton from "@mui/lab/LoadingButton"
import SnackbarContext from '../../lib/context/SnackbarContext'
import { useParams } from 'react-router-dom'
import api from '../../config/axios.config'

export default function ShowCondition () {

  const [readOnly, setReadOnly] = useState(true)
  const [conditionName, setConditionName] = useState('')
  const [conditionCode, setConditionCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [defaultName, setDefaultName] = useState('');
  const [defaultCode, setDefaultCode] = useState('');
  const snackbarCtx = useContext(SnackbarContext);
  const params = useParams();
  
  useEffect(() => {
    getCondition()
  }, [])

  const getCondition = () => {
    api.get(`/api/conditions/${params.condition}`)
    .then((resp) => {
      setConditionName(resp.data?.data?.name)
      setConditionCode(resp.data?.data?.code)
      setDefaults(resp.data?.data?.name, resp.data?.data?.code);
    })
    .catch((err) => {
      console.log(err)
    })
  }

  const setDefaults = (name, code) => {
    setDefaultName(name);
    setDefaultCode(code);
  }

  const handleCancel = () => {
    setConditionName(defaultName);
    setConditionCode(defaultCode);
    setReadOnly(true);
  }

  const checkDisabled = () => {
    let disabled = true;
    if(conditionName.length > 0 && conditionCode.length > 0){
      disabled = false;
    }
    return disabled;
  }

  const saveCondition = () => {
    const body = {
      id: params.condition,
      name: conditionName,
      code: conditionCode
    }
    api.patch(`/api/conditions/${params.condition}`, body)
    .then((resp) => {
      setDefaults(conditionName, conditionCode);
      setReadOnly(true)
      snackbarCtx.showSnackbar(resp.data.message);
    })
    .catch((err) => {
      setReadOnly(true)
      snackbarCtx.showSnackbar("Something went wrong");
    })
  }

  return (
      <>
        <Card className="mt-3" variant="outlined">
          <CardContent>
          
          <div className='d-flex justify-between'>
          <p className="m-0 card-header">CONDITION INFORMATION</p>
              <div> {readOnly && (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={() => setReadOnly(false)}
                    >
                      Edit Condition
                    </Button>
                  )}
                  {!readOnly && (
                    <div>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        onClick={handleCancel}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        className="ml-3"
                        color="secondary"
                        variant="contained"
                        type="submit"
                        loading={loading}
                        onClick={saveCondition}
                        disabled={checkDisabled()}
                      >
                        Save Condition
                      </LoadingButton>
                    </div>
                  )}</div>
               </div>
            
            <Grid className="pt-3" container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      className="w-100"
                      name="name"
                      inputProps={{ readOnly: readOnly }}
                      label="Condition Name *"
                      variant="standard"
                      value={conditionName}
                      onChange={() => setConditionName(event.target.value)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      className="w-100"
                      name="code"
                      inputProps={{ readOnly: readOnly }}
                      label="Condition Code *"
                      variant="standard"
                      value={conditionCode}
                      onChange={() => setConditionCode(event.target.value)}
                    />
                  </Grid>
                </Grid>
          </CardContent>
        </Card>
      </>
  )
}
