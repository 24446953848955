import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import axios from '../lib/axios'
import { formatDate } from '../lib/misc'

import UserContext from '../lib/context/UserContext'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setBulkUploadCount, setBulkUploadPage, setBulkUploads, setBulkUploadSearchParams } from '../store/BulkUpload/bulkupload.actions';
import { useForm } from 'react-hook-form'
import { constructBulkUploadSearchParams, getBulkUploads } from '../helpers/bulkUploadHelper'
import { checkPrivilege } from '../helpers/privilegesHelper'
import { PRIVILEGES } from '../constants/roles.constants'

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
});

const UploadSearchForm = () => {
  // Note that filtering occurs in the front end. These values aren't submitted
  // to the back end.
  const [userFacilities, setUserFacilities] = React.useState([])
  const  dispatch = useDispatch();
  const bulkUploads = useSelector(state => state.bulkUploads)

  const today = new Date()
  const monthAgo = new Date((new Date()).setMonth(today.getMonth() - 1))
  const fromDate = monthAgo.toISOString().substr(0, 10);
  const toDate = today.toISOString().substr(0, 10)

  const resetSearch = () => {
    let params = {
      fromDate: fromDate,
      toDate: toDate,
      selectedFacilities: []
    }
    dispatch(setBulkUploadSearchParams(params))
    updateBulkUploads(params)
  }

  React.useEffect(() => {
    resetSearch()
  }, [])

  React.useEffect(() => {
    // TODO: Test that this is returning the right facilities, and that
    // bulk_uploads is returning the right uploads for users with facility
    // permission
    axios.get('/api/facilities/user_registered.json')
      .then(response => {
        console.log(response)
        setUserFacilities(response.data)
      })
      .catch(e => {
        console.log("Error getting user's facilities", e)
      })
  }, [setUserFacilities])

  const changeFacilities = e => {
    console.log(e)
    let selectedFacilities = [];
    if (e.target.value === 'selectall') {
      selectedFacilities = userFacilities;
    } else {
      selectedFacilities = e.target.value;
    }
    dispatch(setBulkUploadSearchParams({...bulkUploads.params, selectedFacilities: selectedFacilities}))
    updateBulkUploads({...bulkUploads.params, selectedFacilities})
  }

  const updateBulkUploads = (params) => {
    dispatch(setBulkUploadSearchParams(params));
    dispatch(setBulkUploadPage(0))
    getBulkUploads(params);
  }

  return (
    <Card className="mt-3" variant="outlined" style={{ margin: '0px auto', maxWidth: '100%' }}>
      <CardContent>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={3}>
            <TextField
              type="date" onChange={e => updateBulkUploads({...bulkUploads.params, fromDate: e.target.value})}
              label="From date"  value={bulkUploads.params.fromDate} variant="standard"
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="date" onChange={e => updateBulkUploads({...bulkUploads.params, toDate: e.target.value})}
              label="To date"  value={bulkUploads.params.toDate} variant="standard"
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl fullWidth>
              <InputLabel>Select facilities</InputLabel>
              <Select multiple variant='standard'
                  onChange={changeFacilities} value={bulkUploads.params.selectedFacilities} label="Select facilities"
              >
                { /*
                  Even this doesn't work. Gah!
                  <MenuItem value={'selectall'} key={'facility-selectall'}>Select All</MenuItem>
                  */ }
                {userFacilities.map(f => (
                  <MenuItem value={f} key={`facility-${f}`}>{f.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={1}>
            <Button variant="contained" color="secondary" onClick={resetSearch}>Clear</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default function Uploads (props) {
  const [uploads, setUploads] = React.useState([])
  const userContext = React.useContext(UserContext)
  const [relativeUri, setRelativeUri] = React.useState('/')
  const classes = useStyles();

  const bulkUploads = useSelector(state => state.bulkUploads)
  const dispatch = useDispatch();

  const updatePage = (page) => {
    dispatch(setBulkUploadPage(page))
    getBulkUploads(bulkUploads.params, page)
  } 

  React.useEffect(() => {
    setRelativeUri(process.env.DOMAIN_URL + process.env.RELATIVE_URI)
  }, [setRelativeUri])

  React.useEffect(() => {
    getBulkUploads(bulkUploads.params)
  }, [setUploads, props.trigger])

  const fileLink = v => {
    if (checkPrivilege(userContext.currentUser, PRIVILEGES.MANAGE_FACILITIES) ||checkPrivilege(userContext.currentUser, PRIVILEGES.MANAGE_FACILITY) || userContext.currentUser.id === v.user_id) {
      return (
        <a target="_blank" rel="noreferrer" href={relativeUri + '/bulk_uploads/' + v.id} download>
          {v.filename}
        </a>
      )
    } else {
      return v.filename
    }
  }

  const emailLink = v => (
    <a href={`mailto:${v.username}`}>{v.username}</a>
  )

  const columns = [
    { field: 'created_at', headerName: 'Date', flex: true, valueFormatter: formatDate, sortable: false, width: '15%' },
    { field: 'filename', headerName: 'File Name', flex: true, renderCell: fileLink, sortable: false, width: '35%' },
    { field: 'user_full_name', headerName: 'Created User', flex: true, valueGetter: v => `${v.first_name} ${v.last_name}`, sortable: false, width: '15%' },
    { field: 'username', headerName: 'Email', flex: true, renderCell: emailLink, sortable: false, width: '20%' },
    { field: 'facility_name', headerName: 'Facility', flex: true, hide: userContext.currentUser.role === 'Facility Reporter', sortable: false, width: '15%' }
  ]

  

  return (
    <Card className="mt-3" variant="outlined" style={{ margin: '0px auto', maxWidth: '100%', padding: '16px' }}>
      <p className='m-0 card-header'>Search Criteria</p>
      <CardContent>
        <UploadSearchForm />
        { uploads && (
          <div className='mt-3'>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              <div className='bulkuploadsTable' style={{ flexGrow: 1 }}>
                <TableContainer>
                    <Table style={{ tableLayout: "auto" }} className={classes.table} size='small' stickyHeader> 
                      <colgroup>
                        {
                          columns.map((column, index) => (
                            <col key={index} width={column.width} />
                          ))
                        }
                      </colgroup>
                      <TableHead>
                        <TableRow>
                          {columns.map((column, index) => (
                              <TableCell
                                key={index}
                                align='left'
                                style={{ width: `${column.width}`}}
                              >
                                {column.headerName}
                              </TableCell>
                            ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          bulkUploads.uploads.map((uploadItem, index) => {
                            return (
                              <TableRow key={index}>
                                {
                                  columns.map((column, index) => {
                                    return (
                                      column?.renderCell ? (
                                        <TableCell align='left' style={{ padding: '0px'}} key={index}>
                                          {column.renderCell(uploadItem)}
                                        </TableCell>
                                      ) : column?.valueFormatter ? (
                                        <TableCell align='left' style={{ padding: '0px'}} key={index}>
                                          { formatDate(uploadItem?.[column?.field]) }
                                        </TableCell>
                                      ) : (
                                        <TableCell align='left' style={{ padding: '0px'}} key={index}>
                                          {uploadItem[column.field]}
                                        </TableCell>
                                      )
                                    )
                                  })
                                }
                              </TableRow>
                            )
                          })
                        }
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                        <TablePagination
                          count={bulkUploads.count}
                          rowsPerPage={25}
                          rowsPerPageOptions={[]}
                          page={bulkUploads.page}
                          onPageChange={(e, newPage) => updatePage(newPage)}
                        />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </TableContainer>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  )
}
Uploads.propTypes = {
  trigger: PropTypes.number
}
