import { SET_FACILITIES, SET_FACILITY_COUNT, SET_FACILITY_PAGE, SET_FACILITY_SEARCH_PARAMS } from './facilityManagement.types'

export const setFacilityPage = (page) => {
  return {
    type: SET_FACILITY_PAGE,
    page: page
  }
}

export const setFacilityCount = (count) => {
  return {
    type: SET_FACILITY_COUNT,
    count: count
  }
}

export const setFacilitySearchParams = (params) => {
  return {
    type: SET_FACILITY_SEARCH_PARAMS,
    params: params
  }
}

export const setFacilities = (facilities) => {
  return {
    type: SET_FACILITIES,
    facilities: facilities
  }
}
