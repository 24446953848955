import React from 'react'

export default React.createContext({
  message: 'default',
  shown: 'true',
  hideAlert: () => {},
  showAlert: m => {}
})

{ /* <v-alert
v-model="alert"
border="left"
close-text="Close Alert"
dark
type="error"
dismissible
class="multi-line"
>
<pre class="multi-line">{{ errorMessage }}</pre>
</v-alert> */ }
