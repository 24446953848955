import React, { useEffect } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'

import UserContext from '../lib/context/UserContext'
import SourcesContext from '../lib/context/SourcesContext'

import { pathname } from '../lib/misc'

// TODO: Make sure we get to the correct route from /newcase, /bulkupload, etc. as well as simply /

export default function LogOut () {
  const userCtx = React.useContext(UserContext)
  const sourcesCtx = React.useContext(SourcesContext)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const { loggedIn } = React.useContext(UserContext)
  // const dispatch = useDispatch();
  let timeout = process.env.TIMEOUT * 60 * 1000;

  const closeDlg = () => {
    setConfirmOpen(false)
  }

  const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress",
  ];

  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();
      // Listener clean up. Removes the existing event listener from the window
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      if(loggedIn) {
        signOut();
      }
    }, timeout); // 10000ms = 10secs. You can change the time.
  };

  // this resets the timer if it exists.
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const signOut = () => {
    // TODO: When I log out from the dashboard view, it sends me to CAS's
    // logout page. I hit the back button, and it returns me to a dashboard,
    // fully populated with data. I've tried clearing state in the dashboard
    // component on logout, but that's not the problem. Requests to the server
    // to search notifications get 304 Not Modified responses instead of ...
    // whatever an "Unauthenticated" response is.
    closeDlg()
    // dispatch(reset())
    userCtx.logout()
    sourcesCtx.reset()
    window.setTimeout(() => {
      window.location.href = pathname(true) + 'session/sign_out'
    }, 100)
  }

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, [])

  return (
    <>
      <Button variant="contained" color="primary" onClick={() => setConfirmOpen(true)}>Log out</Button>
      <Dialog open={confirmOpen} onClose={closeDlg}>
        <DialogContent>
          Do you want to exit the Disease Reporting Portal?
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={closeDlg}>No</Button>
          <Button variant="contained" color="primary" onClick={signOut}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
