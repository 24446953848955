import { SET_USERS, SET_USER_COUNT, SET_USER_PAGE, SET_USER_SEARCH_PARAMS } from './userManagement.types'

export const setUserPage = (page) => {
  return {
    type: SET_USER_PAGE,
    page: page
  }
}

export const setUserCount = (count) => {
  return {
    type: SET_USER_COUNT,
    count: count
  }
}

export const setUserSearchParams = (params) => {
  return {
    type: SET_USER_SEARCH_PARAMS,
    params: params
  }
}

export const setUsers = (users) => {
  return {
    type: SET_USERS,
    users: users
  }
}
