// The point here is to return the same thing react-hook-form's register()
// does, but renaming `ref` to `inputRef` to work with Material-UI's

import moment from 'moment'

// expectations
const useMuiRegister = register => {
  return (fieldName, opts) => {
    const { name, onChange, onBlur, ref } = register(fieldName, opts)
    return { name, onChange, onBlur, inputRef: ref }
  }
}

// Maybe, get user's timezone for date formatting. Or not, because we know all
// these dates are in the same time zone as the users

const formatDate = (s) => {
  if (!s) return null
  return moment(s).format('MM/DD/YYYY')
}

const pathname = (addslash) => {
  let p = window.location.pathname

  const paths = [
    'newcase',
    'case/',
    'bulkupload',
    'forgot_password',
    'password_reset/',
    'users',
    'user/',
    'facilities',
    'facility/',
    'roles',
    'role/',
    'conditions',
    'condition/',
    'error_submission'
  ]

  // Remove any paths added by the application
  for (const path of paths) {
    const i = p?.indexOf(addslash ? '/' + path : path)
    if (i !== -1) {
      p = p?.slice(0, i)
      break
    }
  }

  if (addslash) {
    if (p?.length === 0) {
      p = '/'
    } else if (p?.slice(-1) !== '/') {
      p = p + '/'
    }
  }

  return p
}

export { useMuiRegister, formatDate, pathname }
