import { SET_BULK_UPLOADS, SET_BULK_UPLOAD_COUNT, SET_BULK_UPLOAD_PAGE, SET_BULK_UPLOAD_SEARCH_PARAMS } from './bulkupload.types'

export const setBulkUploadPage = (page) => {
  return {
    type: SET_BULK_UPLOAD_PAGE,
    page: page
  }
}

export const setBulkUploadCount = (count) => {
  return {
    type: SET_BULK_UPLOAD_COUNT,
    count: count
  }
}

export const setBulkUploadSearchParams = (params) => {
  return {
    type: SET_BULK_UPLOAD_SEARCH_PARAMS,
    params: params
  }
}

export const setBulkUploads = (uploads) => {
  return {
    type: SET_BULK_UPLOADS,
    uploads: uploads
  }
}
