import axios from '../lib/axios'

import { setBulkUploadCount, setBulkUploads } from '../store/BulkUpload/bulkupload.actions'
import store from '../store/store'

const state = store.getState()
const bulkUploads = state.bulkUploads

export const getBulkUploads = (_params, page = 0) => {
  const params = constructBulkUploadSearchParams(_params, page)
  axios
    .get('/bulk_uploads/search', { params })
    .then(response => {
      store.dispatch(setBulkUploads(response.data.bulkUploads))
      store.dispatch(setBulkUploadCount(response.data.total_entries))
    })
    .catch(e => {
      console.log(e)
    })
}

export const constructBulkUploadSearchParams = (e, page = 0) => {
  console.log(e)
  const params = new URLSearchParams()
  if (e.fromDate) {
    params.append('from_date', e.fromDate)
  }
  if (e.toDate) {
    params.append('to_date', e.toDate)
  }
  if (e.selectedFacilities && e.selectedFacilities.length > 0) {
    params.append('facilities', e.selectedFacilities.map(s => s.id).join(','))
  }
  params.append('offset', page + 1)
  params.append('limit', bulkUploads.limit)
  console.log(params)

  return params
}
