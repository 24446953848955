import React from 'react'
import PropTypes from 'prop-types'

import { styled } from '@mui/material/styles'
import axios from '../lib/axios.js'
import UserContext from '../lib/context/UserContext.js'
import RHFMultiSelect from './RHFMultiSelect'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Controller } from 'react-hook-form'

import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
  })(({ theme, expand }) => ({
      transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  })
);

export default function ClinicianInformation (props) {
  const [currentClinicians, setCurrentClinicians] = React.useState([])
  const [clinicianDefaulted, setClinicianDefaulted] = React.useState(false)
  const { currentUser } = React.useContext(UserContext)
  const [facilities, setFacilities] = React.useState([])
  const [states, setStates] = React.useState([])
  const [counties, setCounties] = React.useState([])
  const [expanded, setExpanded] = React.useState(true);
  const [facilitySelected, setFacilitySelected] = React.useState([])

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    setCurrentClinicians([])
    setClinicianDefaulted(false)
  }, [props])

  // Fetching and initalizing the facilities, states, and counties used in autocomplete components
  React.useEffect(() => {
    axios.get('/api/facilities/').then(resp => { 
      setFacilities(resp.data.facilities); 
      setStates(resp.data.states);
      setCounties(resp.data.counties);
    })
    .catch(e => { console.log("Error fetching option sources from /facilities: ", e) }) // TODO: Better error handling
  }, [setFacilities, setStates, setCounties])

  React.useEffect(() => {
    if (!props.caseId) {
      axios.get('/notifications/current_clinician.json')
        .then(response => {
          setCurrentClinicians(response.data)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }, [setCurrentClinicians])

  const clinicianLastChange = (event, value, reason) => {
    console.log('clinicianLastChange', event, value, reason, props)
    if (reason === 'select-option') {
      props.setValue('clinician.first_name', value.first_name)
      setClinicianDefaulted(true)
    }
  }

  const handleFacilitySelection = (e, v) => {
    setFacilitySelected(v)
    if (currentUser.facilities.some((facility) => facility === v)) {
      props.setValue("facility_id", currentUser.facilities.find(facility => facility === v))
      props.setValue("facility.name", currentUser.facilities.find((facility) => facility === v).name)
      props.setValue("clinician.area_code", currentUser.facilities.find((facility) => facility === v)?.area_code)
      // clearErrors('clinician.area_code')
      props.setValue("clinician.phone_number", currentUser.facilities.find((facility) => facility === v)?.phone_number)
      // clearErrors('clinician.phone_number')
      console.log("phone #: ");
      props.setValue("facility.address.street", currentUser.facilities.find((facility) => facility === v)?.full_address.street)
      // clearErrors('facility.address.street')
      props.setValue("facility.address.unit", currentUser.facilities.find((facility) => facility === v)?.full_address.unit)
      // clearErrors('facility.address.unit')
      props.setValue("facility.address.city", currentUser.facilities.find((facility) => facility === v)?.full_address.city)
      // clearErrors('facility.address.city')
      props.setValue("facility.address.zip", currentUser.facilities.find((facility) => facility === v)?.full_address.zip)
      // clearErrors('facility.address.zip')
      props.setValue("facility.address.state_id", states.find((state) => state.id === currentUser.facilities.find((facility) => facility === v)?.full_address.state_id))
      // clearErrors('facility.address.state_id')
      props.setValue("facility.address.county_id", counties.find((county) => county.id === currentUser.facilities.find((facility) => facility === v)?.full_address.county_id))
      // clearErrors('facility.address.county_id')
    } 
  }

  const clinicianPhone = (
    props.readonly
      ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="clinician.phone" fullWidth={true} inputProps={{ readOnly: props.readonly }}
                InputLabelProps={{ shrink: true }} variant="standard"
                label="Phone Number" data-testid="clinician.phone"
                {...props.register('clinician.phone')}
              />
            </Grid>
          </Grid>
        )
      : (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                name="clinician.area_code" inputProps={{ readOnly: props.readonly }}
                label="Area Code" variant="standard"
                error={!!props.errors.clinician?.area_code} helperText={props.errors.clinician?.area_code && props.errors.clinician?.area_code.message}
                {...props.register('clinician.area_code')}
                InputLabelProps={{ shrink: props.watch('clinician.area_code') !== '' }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="clinician.phone_number" inputProps={{ readOnly: props.readonly }}
                label="Phone Number" variant="standard"
                error={!!props.errors.clinician?.phone_number} helperText={props.errors.clinician?.phone_number && props.errors.clinician?.phone_number.message}
                {...props.register('clinician.phone_number')}
                InputLabelProps={{ shrink: props.watch('clinician.phone_number') !== '' }}
              />
            </Grid>
          </Grid>
        )
  )

  return (
    <Card variant="outlined">
      <CardHeader
        action={
          <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
        title={<p className='m-0 card-header'>CLINICIAN INFORMATION</p>}
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
      <CardContent>
        <Grid className='py-3' container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="subtitle2">Facility</Typography>
            <div>
              <RHFMultiSelect
                name="facility_id" fullWidth register={props.register}
                readOnly={props.readonly}
                addBlankOption={true}
                control={props.control} label="Facility *"
                options={currentUser.facilities}
                getOptionLabel={o => o?.name || ''}
                getOptionSelected={o => o?.name || ''}
                onChange={handleFacilitySelection}
                error={!!props.errors?.facility_id} 
                helperText={props.errors?.facility_id && props.errors?.facility_id.message}
                objGOS={true}
                inputProps={{"data-testid": "facility_id"}}
              />
            </div>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">Clinician</Typography>
            <div>
              { isNaN(props.caseId)
                ? (
                  <Controller
                    control={props.control}
                    name="clinician.last_name"
                    render={() => {
                      let temp = false
                      if (props.clinLastName) {
                        temp = true
                        props.setClinLastName(false)
                      }
                      return(<Autocomplete
                        options={currentClinicians}
                        freeSolo fullWidth
                        defaultValue=''
                        getOptionLabel={o => o?.last_name || ''}
                        value={temp && ''}
                        onChange={clinicianLastChange}
                        renderInput={(params) => {
                          return (
                            <TextField {...params}
                              variant="standard"
                              InputLabelProps={{ shrink: clinicianDefaulted || props.watch('clinician.last_name') !== '' }}
                              inputProps={{ ...params.inputProps }}
                              {...props.register('clinician.last_name')}
                              label="Clinician Last Name" 
                            />
                          )
                        }}
                        renderOption={o => `${o.last_name}, ${o.first_name}` || ''}
                    />)}}
                  />
                  )
                : (
                    <TextField className="w-100"
                      name="clinician.last_name" inputProps={{ readOnly: true }}
                      label="Clinician Last Name" variant="standard"
                      InputLabelProps={{ shrink: clinicianDefaulted || props.watch('clinician.last_name') !== '' }}
                      {...props.register('clinician.last_name')}
                    />
                  )
              }
            </div>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">&nbsp;</Typography>
            <div>
              <TextField className="w-100"
                name="clinician.first_name" inputProps={{ readOnly: props.readonly }}
                label="Clinician First Name" variant="standard"
                InputLabelProps={{ shrink: clinicianDefaulted || props.watch('clinician.first_name') !== '' }}
                {...props.register('clinician.first_name')}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle2">Clinician (or Facility)</Typography>
            <div data-testid="clinicianPhone">
              { clinicianPhone }
            </div>
          </Grid>
          <Grid item xs={3}>
            <TextField
              disabled={!props.readonly}  
              InputProps={{ readOnly: props.readonly}}  
              InputLabelProps={{ shrink: props.watch('street') !== '' }}  
              label="Street *" variant="standard" className="w-100"              
              {...props.register('facility.address.street')}
              error={!!props.errors.street }
              helperText={ props.errors.street && props.errors.street.message }
              inputProps={{"data-testid": "facility.address.street"}}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              // Saved sample of this in case of possiblity to update facility with case submission.
              // disabled={facilities.find((facility) => facility?.[0] === props.currentUser.facility_id) && !props.readonly}  
              // InputProps={{ readOnly: facilities.find((facility) => facility?.[0] === props.currentUser.facility_id) && props.readonly }}   
              disabled={!props.readonly}  
              InputProps={{ readOnly: props.readonly}}
              label="Unit" variant="standard" className="w-100"              
              {...props.register('facility.address.unit')}
              InputLabelProps={{ shrink: props.watch('unit') !== '' }} 
              inputProps={{"data-testid": "facility.address.unit"}}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              disabled={!props.readonly}  
              InputProps={{ readOnly: props.readonly}}  
              label="City *" variant="standard" className="w-100"                 
              {...props.register('facility.address.city')}
              InputLabelProps={{ shrink: props.watch('city') !== '' }} 
              error={!!props.errors.city }
              helperText={ props.errors.city && props.errors.city.message }
              inputProps={{"data-testid": "facility.address.city"}}
            />
          </Grid>
          <Grid item xs={1}>
            <RHFMultiSelect
              name="facility.address.state_id" fullWidth 
              register={props.register}
              disabled={!props.readonly}  
              readOnly={props.readonly}
              control={props.control} 
              label="State *"
              options={states}
              getOptionLabel={o => o?.code || ''}
              getOptionSelected={o => o?.code || ''}
              error={!!props.errors.state_id} 
              helperText={props.errors.state_id && props.errors.state_id.message}
              objGOS={true}
              inputProps={{"data-testid": "facility.address.state_id"}}
            />
          </Grid>
          <Grid item xs={2}>
            <RHFMultiSelect
              name="facility.address.county_id" fullWidth 
              disabled={!props.readonly}  
              readOnly={props.readonly}
              register={props.register}
              label="County *" 
              getOptionLabel={o => o?.name || ''}
              getOptionSelected={o => o?.name || ''}
              control={props.control}
              options={counties} 
              variant="standard"
              error={!!props.errors.county_id} 
              helperText={props.errors.county_id && props.errors.county_id.message}
              objGOS={true}
              inputProps={{"data-testid": "facility.address.county_id"}}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              label="Zip *" variant="standard"  className="w-100"   
              disabled={!props.readonly}  
              InputProps={{ readOnly: props.readonly}}
              {...props.register('facility.address.zip')}
              InputLabelProps={{ shrink: props.watch('facility.address.zip') !== '' }} 
              error={!!props.errors.zip }
              helperText={ props.errors.zip && props.errors.zip.message }
              inputProps={{"data-testid": "facility.address.zip"}}
            />
          </Grid>
        </Grid>
      </CardContent>
      </Collapse>
    </Card>
  )
}
ClinicianInformation.propTypes = {
  errors: PropTypes.shape({
    clinician: PropTypes.shape({
      area_code: PropTypes.object,
      phone_number: PropTypes.object
    })
  }),
  caseId: PropTypes.string,
  setValue: PropTypes.func,
  readonly: PropTypes.bool,
  register: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentUser: PropTypes.object,
  clinLastName: PropTypes.bool,
  setClinLastName: PropTypes.func
}
