import React from 'react'
import PropTypes from 'prop-types'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'

import RHFMultiSelect from './RHFMultiSelect'

import SourcesContext from '../lib/context/SourcesContext'
import { useWatch } from 'react-hook-form'

export default function DiseaseInformation (props) {
  const {
    conditions, facilityVisitTypes, labTypes,
    specimenSources, sourcesLoaded
  } = React.useContext(SourcesContext)
  // const specimenResultsReceived = useWatch({ control: props.control, name: 'specimen_sent' })

  // if ((specimenResultsReceived && {}.hasOwnProperty.call(specimenResultsReceived, 'value') && specimenResultsReceived.value) || specimenResultsReceived == 'Yes') {
  //   return (
  //     <Card className="mt-3" variant="outlined">
  //       <CardContent>
  //         <p className='m-0 card-header'>DISEASE AND SPECIMEN INFORMATION</p>
  //         <Grid className='pt-3' container spacing={2}>
  //           <Grid item xs={6}>
  //             { sourcesLoaded && (
  //               <RHFMultiSelect
  //                 name="condition_id" fullWidth register={props.register}
  //                 readOnly={props.readonly }
  //                 addBlankOption={true}
  //                 label="Disease *" control={ props.control }
  //                 options={conditions}
  //                 objGOS={true}
  //                 error={!!props.errors.condition_id} helperText={props.errors.condition_id && props.errors.condition_id.message}
  //               />
  //             )}
  //           </Grid>
  //           <Grid item xs={6}>
  //             { sourcesLoaded && (
  //               <RHFMultiSelect
  //                 name="facility_visit_type_id" fullWidth register={props.register}
  //                 readOnly={props.readonly }
  //                 addBlankOption={true}
  //                 label="Hospitalization Status *" control={ props.control }
  //                 options={facilityVisitTypes}
  //                 error={!!props.errors.facility_visit_type_id} helperText={props.errors.facility_visit_type_id && props.errors.facility_visit_type_id.message}
  //                 objGOS={true}
  //               />
  //             )}
  //           </Grid>
  //         </Grid>

  //         <Grid className='pt-3' container spacing={2}>
  //           <Grid item xs={6}>
  //             <RHFMultiSelect
  //               name="specimen_sent" fullWidth register={props.register}
  //               readOnly={props.readonly }
  //               addBlankOption={true}
  //               label="Specimen collected for testing? *" control={ props.control }
  //               options={ [{ id: 1, name: 'Yes', value: true }, { id: 2, name: 'No', value: false }] }
  //               error={!!props.errors.specimen_sent} helperText={props.errors.specimen_sent && props.errors.specimen_sent.message}
  //               objGOS={true}
  //             />
  //               { /* I dunno why objGOS doesn't work above, but don't care to dive into it
  //               getOptionSelected={(a, b) => a === b || (b.hasOwnProperty('id') ? b.id === a.id : b === a.name)}
  //               */ }
  //           </Grid>

  //           <Grid item xs={6}>
  //             { sourcesLoaded && (
  //               <RHFMultiSelect
  //                 name="specimen_source_id" fullWidth register={props.register}
  //                 readOnly={props.readonly }
  //                 addBlankOption={true}
  //                 label="Specimen source *" control={ props.control }
  //                 options={specimenSources}
  //                 error={!!props.errors.specimen_source_id} helperText={props.errors.specimen_source_id && props.errors.specimen_source_id.message}
  //                 objGOS={true}
  //               />
  //             )}
  //           </Grid>
  //         </Grid>

  //         <Grid className='pt-3' container spacing={2}>
  //           <Grid item xs={6}>
  //             { sourcesLoaded && (
  //               <RHFMultiSelect
  //                 name="lab_type_id" fullWidth register={props.register}
  //                 readOnly={props.readonly }
  //                 addBlankOption={true}
  //                 label="Specimen sent to *" control={ props.control }
  //                 options={labTypes}
  //                 error={!!props.errors.lab_type_id} helperText={props.errors.lab_type_id && props.errors.lab_type_id.message}
  //                 objGOS={true}
  //               />
  //             )}
  //           </Grid>

  //           <Grid item xs={6}>
  //             { /* NB: The Vue portal considers this a date, and disregards time */ }
  //             <TextField
  //               name="specimen_sent_at" type="datetime-local" InputLabelProps={{ shrink: true }}
  //               inputProps={{ readOnly: props.readonly }}
  //               label="Specimen Collection Date *" {...props.register('specimen_sent_at')}
  //               error={!!props.errors.specimen_sent_at} helperText={props.errors.specimen_sent_at && props.errors.specimen_sent_at.message}
  //             />
  //           </Grid>
  //         </Grid>

  //         <Grid className='pt-3' container spacing={2}>
  //           <Grid item xs={8}>
  //             <TextField
  //               name="comment" multiline={true} rows={3} fullWidth
  //               inputProps={{ readOnly: props.readonly }}
  //               label="Comment (please include symptoms and specimen routing information)"
  //               {...props.register('comment')}
  //             />
  //           </Grid>
  //         </Grid>
  //       </CardContent>
  //     </Card>
  //   )
  // }
  return (
    <Card className="mt-3" variant="outlined">
      <CardContent>
        <p className='m-0 card-header'>DISEASE AND SPECIMEN INFORMATION</p>
        <Grid className='pt-3' container spacing={2}>
          <Grid item xs={6}>
            { (sourcesLoaded) && (
              <RHFMultiSelect
                name="condition_id" fullWidth register={props.register}
                readOnly={props.readonly }
                // addBlankOption={true}
                label="Disease *" control={ props.control }
                defaultValue={{ id: 1, name: 'Coronavirus Disease 2019 (COVID-19)', code: '279' }}
                options={conditions}
                objGOS={true}
                error={!!props.errors.condition_id} helperText={props.errors.condition_id && props.errors.condition_id.message}
                inputProps={{"data-testid": "condition_id"}}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            { (sourcesLoaded) && (
              <RHFMultiSelect
                name="facility_visit_type_id" fullWidth register={props.register}
                readOnly={props.readonly }
                addBlankOption={true}
                label="Hospitalization Status *" control={ props.control }
                options={facilityVisitTypes}
                error={!!props.errors.facility_visit_type_id} helperText={props.errors.facility_visit_type_id && props.errors.facility_visit_type_id.message}
                objGOS={true}
                inputProps={{"data-testid": "facility_visit_type_id"}}
              />
            )}
          </Grid>
        </Grid>

        <Grid className='pt-3' container spacing={2}>
          <Grid item xs={6}>
            { (sourcesLoaded) && (
              <RHFMultiSelect
                name="specimen_source_id" fullWidth register={props.register}
                readOnly={props.readonly }
                addBlankOption={true}
                label="Specimen Source *" control={ props.control }
                options={specimenSources}
                error={!!props.errors.specimen_source_id} helperText={props.errors.specimen_source_id && props.errors.specimen_source_id.message}
                objGOS={true}
                inputProps={{"data-testid": "specimen_source_id"}}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <TextField
              name="specimen_sent_at" type="date" pattern="\d{4}-\d{2}-\d{2}" InputLabelProps={{ shrink: true }}
              inputProps={{ "data-testid": "specimen_sent_at", readOnly: props.readonly }} className="w-100" variant="standard"
              label="Specimen Collection Date *" {...props.register('specimen_sent_at')}
              error={!!props.errors.specimen_sent_at} helperText={props.errors.specimen_sent_at && props.errors.specimen_sent_at.message}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
DiseaseInformation.propTypes = {
  control: PropTypes.object,
  readonly: PropTypes.bool,
  register: PropTypes.func,
  caseId: PropTypes.string,
  errors: PropTypes.shape({
    specimen_sent: PropTypes.object,
    specimen_sent_at: PropTypes.object,
    specimen_source_id: PropTypes.object,
    facility_visit_type_id: PropTypes.object,
    condition_id: PropTypes.object,
    lab_type_id: PropTypes.object
  })
}
// { /* TODO: Change specimenSourceId from code to value, if we've loaded a case */ }
// { /* Older version of source id, may be required in future
//               { sourcesLoaded && (
//                 isNaN(props.caseId)
//                   ? (
//                       <FormControl fullWidth error={!!props.errors.specimenSourceId}>
//                         <Autocomplete
//                           options={specimenSources}
//                           freeSolo fullWidth
//                           getOptionLabel={o => o.name}
//                           getOptionSelected={(o,v) => o === v.name}
//                           onChange={(e, n) => changeMe(e, n, onChange)}
//                           renderInput={(params) => {
//                             return (
//                               <TextField {...params}
//                                 name="specimenSourceId"
//                                 inputProps={{ ...params.inputProps }}
//                                 label="Specimen source *" {...props.register('specimenSourceId')}
//                               />
//                             )
//                           }}
//                           renderOption={o => o.name}
//                         />
//                         {
//                           props.errors.specimenSourceId && (
//                             <FormHelperText>{props.errors.specimenSourceId.message}</FormHelperText>
//                           )
//                         }
//                       </FormControl>
//                     )
//                   : (
//                       <TextField
//                         inputProps={{ readOnly: true }} name="specimenSourceId" fullWidth
//                         label="Specimen source" {...props.register('specimenSourceId')}
//                       />
//                     )
//               )} */ }

/*   VUE VERSION
          <v-row>
            <v-col
              sm="6"
              md="3"
              tile
            >
              <v-select
                v-model="showCase.notification.specimen_sent"
                :items="yesno"
                item-text="name"
                item-value="code"
                label="Specimen collected for testing?"
                regular
                readonly
                append-icon=""
                dense
              />
            </v-col>
            <v-col
              v-if="showCase.notification.specimen_sent"
              sm="6"
              md="3"
            >
              <v-autocomplete
                v-model="showCase.notification.specimen_source_id"
                :items="specimen_sources"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                label="Specimen Source"
                regular
                readonly
                append-icon=""
                dense
              />
            </v-col>
            <v-col
              v-if="showCase.notification.specimen_sent"
              sm="6"
              md="2"
            >
              <v-select
                v-model="showCase.notification.lab_type_id"
                :items="lab_types"
                item-text="name"
                item-value="id"
                label="Specimen sent to?"
                regular
                readonly
                append-icon=""
                dense
              />
            </v-col>
            <v-col
              v-if="showCase.notification.specimen_sent"
              sm="6"
              md="3"
            >
              <v-text-field
                v-model="computedSpecimenSentAt"
                label="Specimen Collection Date"
                hint="MM/DD/YYYY format"
                persistent-hint
                readonly
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              sm="7"
              md="7"
              tile
            >
              <v-textarea
                v-model="showCase.notification.comment"
                regular
                readonly
                name="input-7-4"
                label="Comment (symptoms and specimen routing information)"
                dense
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
*/
