import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContext from '../lib/context/SnackbarContext'

export default function PortalSnackbar () {
  return (
    <SnackbarContext.Consumer>
      {ctx => (
        <Snackbar
          open={ctx.shown} onClose={ctx.hideSnackbar}
          message={ctx.message} autoHideDuration={6000}
        />
      )}
    </SnackbarContext.Consumer>
  )
};
