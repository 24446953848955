import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Card from '@mui/material/Card'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useForm } from 'react-hook-form'
import { getUsers } from '../../helpers/userManagementHelper'
import { setUserSearchParams } from '../../store/UserManagement/userManagement.actions'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { InputLabel, MenuItem, Select } from '@mui/material'

export default function UserSearch () {

    const { register, handleSubmit, control, reset } = useForm()
    const dispatch = useDispatch();
    const users = useSelector(state => state.users)
    const [status, setStatus] = useState([])
    const statuses = ['ACTIVE', 'PENDING', 'DISABLED', 'LOCKED']
  
    const search = (e) => {
      e['status'] = status;
      dispatch(setUserSearchParams(e))
      getUsers(e);
    }

    const resetValues = () => {
      reset(defaultValue);
      dispatch(setUserSearchParams(defaultValue))
      getUsers(defaultValue);
    }

    const changeStatus = (event) => {
      setStatus(event.target.value);
    };

    
    const defaultValue = {
      lastName: '',
      firstName: '',
      email: ''
    }

    useEffect(() => {
      return () => {
        reset(defaultValue);
        dispatch(setUserSearchParams(defaultValue));
      }
    }, [])

    return (
      <Card variant="outlined">
        <form onSubmit={handleSubmit(search)}><FormControl fullWidth>
          <CardContent>
            <p className='card-header m-0'>Search Criteria</p>
            <Grid className='py-3' container justifyContent='space-between' alignContent='center'>
              <Grid item xs={3}>
                <TextField className="w-100" variant='standard'
                  label="Enter Last Name" name="lastName" {...register('lastName')} />
              </Grid>
              <Grid item xs={3}>
                <TextField className="w-100" variant='standard'
                  label="Enter First Name" name="firstName" {...register('firstName')} />
              </Grid>
              <Grid item xs={3}>
                <TextField className="w-100" variant='standard'
                  label="Enter Email" name="email" {...register('email')} />
              </Grid>
              <Grid container item xs={2} justifyContent='space-between'>
                <Grid item>
                  <Button variant="contained" color="primary" onClick={() => resetValues()}>Clear</Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="secondary" type="submit">Search</Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
            <FormControl
                variant="outlined"
                style={{ width: "150px" }}
              >
                <InputLabel id="status-label">Status *</InputLabel>
                <Select
                  variant="standard"
                  value={status}
                  onChange={changeStatus}
                  label="Status *"
                  labelId='status-label'
                  multiple
                >
                  {statuses?.map((status, index) => {
                    return (
                      <MenuItem key={index} value={status}>
                        {status}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </CardContent>
        </FormControl></form>
      </Card>
    )
}
