import { SET_PATIENTS_SEARCHED, SET_PATIENT_ID, SET_PATIENT_SEARCH_RESULTS, SET_PATIENT_SELECTED } from "./patientSearch.types"

const INITIAL_STATE = {
    patients: [],
    searched: false,
    patientSelected: false,
    patientId: null
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case SET_PATIENT_SEARCH_RESULTS:
        return {
          ...state, patients: action.patients
        }
      case SET_PATIENTS_SEARCHED:
        return {
          ...state, searched: action.searched
        }
      case SET_PATIENT_SELECTED:
        return {
          ...state, patientSelected: action.selected
        }
      case SET_PATIENT_ID:
        return {
          ...state, patientId: action.id
        }
      default:
        return state
    }
  }
  
  export default reducer