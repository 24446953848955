import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { pathname } from "../lib/misc";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  banner: {
    padding: "0px !important",
    width: "100% !important",
  },
});

export default function PortalHeader() {
  const header = useSelector((state) => state.config.config.header);
  const classes = useStyles();
  const state = process.env.DEFAULT_STATE;
  const [headerPresent, setHeaderPresent] = useState(true);

  return (
    headerPresent && (
      <img
        className={classes.banner}
        src={process.env.RELATIVE_URI + `state-assets/images/portal-header.jpg`}
        alt={`${state} header`}
        onError={() => setHeaderPresent(false)}
      />
    )
  );
}
