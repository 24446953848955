import { SET_CONFIG } from './config.types'
import Config from '../../../../config.json'

const INITIAL_STATE = {
  config: Config
}

const config = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return {
        ...state, config: action.config
      }
    
    default:
      return state
  }
}

export default config
