import axios from '../lib/axios'

import { setUserCount, setUsers } from '../store/UserManagement/userManagement.actions'
import store from '../store/store'

const state = store.getState()
const users = state.users

export const getUsers = (_params, page = 0) => {
  const params = constructUserSearchParams(_params, page)
  axios
    .get('/api/users/search', { params })
    .then(response => {
      console.log(response)
      store.dispatch(setUsers(response.data.users))
      store.dispatch(setUserCount(response.data.total_entries))
    })
    .catch(e => {
      console.log(e)
    })
}

export const constructUserSearchParams = (e, page = 0) => {
  const params = new URLSearchParams()
  if (e?.firstName) {
    params.append('first_name', e.firstName)
  }
  if (e?.lastName) {
    params.append('last_name', e.lastName)
  }
  if (e?.email) {
    params.append('username', e.email)
  }
  if (e?.status) {
    params.append('status', e.status)
  }
  params.append('offset', page + 1)
  params.append('limit', users.limit)

  return params
}

export const getRolesOptions = (roles) => {
  console.log(roles)
  return roles.map((role) => {
    return {
      label: role.name,
      value: role.id
    }
  })
}