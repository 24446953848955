import React from 'react'
import { useForm } from 'react-hook-form'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import SnackbarContext from '../lib/context/SnackbarContext'
import axios from '../lib/axios.js'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import RHFMultiSelect from './RHFMultiSelect'
import UserContext from '../lib/context/UserContext'
import DialogContent from '@mui/material/DialogContent'
import Dialog from '@mui/material/Dialog'


const theme = createTheme({
  palette: {
    primary: {
      main: '#fb8c00',
      contrastText: '#fff'
    },
    secondary: {
      main: '#66bb6a'
    }
  }
})

export default function FacilityUpdatePopup () {
    const snackbarCtx = React.useContext(SnackbarContext)
    const [facilities, setFacilities] = React.useState([])
    const [states, setStates] = React.useState([])
    const { currentUser } = React.useContext(UserContext)
    const [counties, setCounties] = React.useState([])
    const [registerDialog, setRegisterDialog] = React.useState(true)
    const [currentFacility, setCurrentFacility] = React.useState([])
    const dialogClose = (event, reason) => {
        // Don't close the dialog by clicking outside it
        if (reason !== 'backdropClick') { setRegisterDialog(false) }
    }

    React.useEffect(() => {
        axios.get('/api/facilities/').then(resp => { 
          setFacilities(resp.data.facilities)
          setStates(resp.data.states)
          setCounties(resp.data.counties)
          setCurrentFacility(currentUser?.facilities[0])
        })
        .catch(e => { console.log("Error fetching option sources from /facilities: ", e) })
    }, [setFacilities, setStates, setCounties, setCurrentFacility ])

    const { register, handleSubmit, getValues, control, watch, formState: { errors } } = useForm({ 
        mode: 'all',     
        defaultValues: {
            phone_number: currentFacility.phone_number ? currentFacility.phone_number : '' ,
            area_code: currentFacility.area_code ? currentFacility.area_code : '',
            street: currentFacility.street ? currentFacility.street : '',
            unit: currentFacility.unit ? currentFacility.unit : '',
            city: currentFacility.city ? currentFacility.city : '',
            zip: currentFacility.zip ? currentFacility.zip  : '',
            county_id: currentFacility.county_id ? currentFacility.county_id : '',
            state_id: currentFacility.state_id ? currentFacility.state_id : ''
        }
    })

    const doHandleSubmit = function (values) {
        values.facility = currentFacility.name
        values.facility_id = currentFacility.id
        console.log('Values', values)

        const updateparams = { id: values.facility_id, area_code: values.area_code, phone_number: values.phone_number, address: { state_id: values.state_id.id, county_id: values.county_id.id, unit: values.unit, zip: values.zip, city: values.city, street: values.street } }
        console.log('Update params', updateparams)

        axios.post('/facilities/update_address', {
            facility: updateparams
        }).then(response => {
            snackbarCtx.showSnackbar('Facility address successfully updated')
        }).catch(error => {
            console.log('Failed to Update Facility', error)
            if (error) {
                snackbarCtx.showSnackbar('Couldn\'t update facility.')
            }
        })
        setRegisterDialog(false)
    }

    
    return (
        <>
        {
            (currentFacility && currentFacility.address_id === null) && (
                <Dialog open={ registerDialog } onClose={dialogClose} fullWidth={true} maxWidth={'lg'}>
        <DialogContent>
            <div>
            <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Card variant="outlined" style={{ margin: '0px auto', display: 'flex', height: '100%', flexWrap: 'wrap', justifyContent: 'center' }} >
                <CardHeader title='Update Required for Current Facility' style={{ textAlign: 'center', padding: '10px' }}></CardHeader>
                <form onSubmit={handleSubmit(doHandleSubmit)}>
                <CardContent>
                    <div>
                        Please fill in current facility address and contact fields. 
                    </div><br/>
                    <Grid container className='pt-3' spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                className="w-100"  
                                name="facility" 
                                value={currentFacility.name}
                                inputProps={{ readOnly: true }} 
                                InputLabelProps={{ shrink: watch('facility') !== '' }}
                                variant="standard"
                                label="Facility Name" 
                                {...register('facility')}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                            disabled={currentFacility.street}  
                            InputProps={{ readOnly: currentFacility.street }}  
                            InputLabelProps={{ shrink: watch('street') !== '' }}  
                            label="Street *" variant="standard" className="w-100"              
                            {...register(
                                'street', {
                                required: { value: true, message: 'Required' },
                                }
                            )}
                            error={!!errors.street }
                            helperText={ errors.street && errors.street.message }
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                            disabled={currentFacility.unit}  
                            InputProps={{ readOnly: currentFacility.unit }}   
                            label="Unit" variant="standard" className="w-100"              
                            {...register('unit')}
                            InputLabelProps={{ shrink: watch('unit') !== '' }} 
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                            disabled={currentFacility.city}  
                            InputProps={{ readOnly: currentFacility.city }}   
                            label="City *" variant="standard" className="w-100"                 
                            {...register(
                                'city', {
                                required: { value: true, message: 'Required' },
                                }
                            )}
                            InputLabelProps={{ shrink: watch('city') !== '' }} 
                            error={!!errors.city }
                            helperText={ errors.city && errors.city.message }
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <RHFMultiSelect
                            name="state_id" fullWidth 
                            register={{...register('state_id', {
                                required: { value: true, message: 'Required' }
                            })}}
                            disabled={currentFacility.state_id}  
                            readOnly={currentFacility.state_id}
                            control={control} 
                            label="State *"
                            options={states}
                            getOptionLabel={o => o.code || ''}
                            getOptionSelected={o => o.code || ''}
                            error={!!errors.state_id} 
                            helperText={errors.state_id && errors.state_id.message}
                            objGOS={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <RHFMultiSelect
                            name="county_id" fullWidth 
                            disabled={currentFacility.county_id}  
                            readOnly={currentFacility.county_id}
                            register={{...register('county_id', {
                                required: { value: true, message: 'Required' }
                            })}}
                            label="County *" 
                            getOptionLabel={o => o.name || ''}
                            getOptionSelected={o => o.name || ''}
                            control={control}
                            options={counties} 
                            variant="standard"
                            error={!!errors.county_id} 
                            helperText={errors.county_id && errors.county_id.message}
                            objGOS={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                            label="Zip *" variant="standard"  className="w-100"   
                            disabled={currentFacility.zip} 
                            InputProps={{ readOnly: currentFacility.zip }} 
                            
                            // Option to allow for the updating of address for existing facilities
                            // InputProps={{ readOnly: facilities.find((facility) => facility.includes(check))?.[6] }}               
                            // disabled={facilities.find((facility) => facility.includes(check))?.[6]}
                            {...register(
                                'zip', {
                                required: { value: true, message: 'Required' },
                                pattern: { value: /^[0-9]{5}(-[0-9]{4})?$/, message: 'Zip Code 5 digits or 5 digits - 4 digits formats' },
                                })
                            }
                            InputLabelProps={{ shrink: watch('zip') !== '' }} 
                            error={!!errors.zip }
                            helperText={ errors.zip && errors.zip.message }
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField 
                            className="w-100"
                            label="Area Code *" variant="standard"            
                            {...register (
                                'area_code', 
                                { 
                                required: { value: true, message: 'Required' },
                                pattern: { value: /^\d\d\d$/, message: 'Must be three digits' }
                                }
                            )}
                            disabled={currentFacility.area_code}  
                            InputProps={{ readOnly: currentFacility.area_code }}  
                            InputLabelProps={{ shrink: watch('area_code') !== '' }} 
                            error={!!errors.area_code }
                            helperText={ errors.area_code && errors.area_code.message }
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                className="w-100"
                                label="Phone Number *" variant="standard"
                                {...register(
                                'phone_number', {
                                    required: { value: true, message: 'Required' },
                                    pattern: { value: /^\d{7}$/, message: 'Must be seven digits' },
                                }
                                )}
                                disabled={currentFacility.phone_number}  
                                InputProps={{ readOnly: currentFacility.phone_number }}   
                                InputLabelProps={{ shrink: watch('phone_number') !== '' }} 
                                error={!!errors.phone_number }
                                helperText={ errors.phone_number && errors.phone_number.message }
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <div style={{ flex: '1 0 0' }} />
                    <Button color="primary" variant="outlined" onClick={() => {setRegisterDialog(false)}}>CANCEL</Button>
                    <Button color="secondary" variant="outlined" type="submit">Update</Button>
                    <div style={{ flex: '1 0 0' }} />
                </CardActions>
                </form>
                </Card>
            </ThemeProvider>
            </StyledEngineProvider>
            </div>
        </DialogContent>
        </Dialog>
        )
        }
        </>
        )
    }