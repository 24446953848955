import store from '../store/store'

const state = store.getState()
const notifications = state.notifications

export const constructNotificationSearchParams = (e, page = 0) => {
  const params = new URLSearchParams()
  if (e.lastName) {
    params.append('last_name', e.lastName)
  }
  if (e.fromDate) {
    params.append('from_date', e.fromDate)
  }
  if (e.toDate) {
    params.append('to_date', e.toDate)
  }
  if (e.selectedConditions && e.selectedConditions.length > 0) {
    params.append('conditions', e.selectedConditions.map(s => s.id).join(','))
  }
  if (e.selectedFacilities && e.selectedFacilities.length > 0) {
    params.append('facilities', e.selectedFacilities.map(s => s.id).join(','))
  }
  if (e.selectedCounties && e.selectedCounties.length > 0) {
    params.append('counties', e.selectedCounties.map(s => s.id).join(','))
  }
  params.append('offset', page + 1)
  params.append('limit', notifications.limit)

  return params
}
