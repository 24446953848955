import React from 'react'

import { useForm } from 'react-hook-form'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import SnackbarContext from '../lib/context/SnackbarContext'
import axios from '../lib/axios.js'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import RHFMultiSelect from './RHFMultiSelect'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const theme = createTheme({
  palette: {
    primary: {
      main: '#fb8c00',
      contrastText: '#fff'
    },
    secondary: {
      main: '#66bb6a'
    }
  }
})

export default function RegisterUser ({ setOpen, open }) {
  const snackbarCtx = React.useContext(SnackbarContext)
  const [facilities, setFacilities] = React.useState([])
  const [states, setStates] = React.useState([])
  const [counties, setCounties] = React.useState([])
  const [facilitySelected, setFacilitySelected] = React.useState('')
  const { register, clearErrors, handleSubmit, getValues, setValue, control, watch, formState: { errors } } = useForm({ 
    mode: 'all',
    defaultValues: {
      phone_number: '',
      area_code: '',
      street: '',
      unit: '',
      city: '',
      zip: '',
      county_id: '',
      state_id: ''
    }
  })
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Fetching and initalizing the facilities, states, and counties used in autocomplete components
  React.useEffect(() => {
    axios.get('/api/facilities/').then(resp => { 
      setFacilities(resp.data.facilities); 
      setStates(resp.data.states);
      setCounties(resp.data.counties);
    })
    .catch(e => { console.log("Error fetching option sources from /facilities: ", e) }) // TODO: Better error handling
  }, [setFacilities, setStates, setCounties])

  const doHandleSubmit = async function (values) {
    const token = await executeRecaptcha('Validate_registration')
    values.grecaptcha_token = token;
    var updateRequired = false
    const existingFacilityCheck = facilities.find((facility) => facility.name === values.facility)
    if (existingFacilityCheck) {
      const addressValues = {area_code: existingFacilityCheck.area_code, phone_number: existingFacilityCheck.phone_number, street: existingFacilityCheck.street, state_id: existingFacilityCheck.state_id, county_id: existingFacilityCheck.county_id, unit: existingFacilityCheck.unit, zip: existingFacilityCheck.zip}
      for (const prop in addressValues) {
        if (!addressValues[prop]) {
          console.log(prop)
          updateRequired = true
        }
      }
    }

    if (updateRequired) {
      values.facility_id = existingFacilityCheck.id
      const updateparams = { id: values.facility_id, area_code: values.area_code, phone_number: values.phone_number, address: { state_id: values.state_id.id, county_id: values.county_id.id, unit: values.unit, zip: values.zip, city: values.city, street: values.street } }
      console.log('Update params', updateparams)
      axios.post('/facilities/update_address', {
        facility: updateparams
      }).then(response => {
        snackbarCtx.showSnackbar('Updated Facility Address')
      }).catch(error => {
        console.log('Failed to Update Facility', error)
        if (error) {
          snackbarCtx.showSnackbar('Couldn\'t update facility.')
        }
      })
    }

    const submitparams = { ...values, facility: { id: values.facility_id, name: values.facility, area_code: values.area_code, phone_number: values.phone_number, address: {street: values.street, unit: values.unit, city: values.city, zip: values.zip, state_id: values.state_id.id, county_id: values.county_id.id } } }
    const {facility_id, area_code, phone_number, street, zip, city, unit, state_id, county_id, ...params} = submitparams
    axios.post('/session/register', {
      user: params
    }).then(response => {
      setOpen(false)
      snackbarCtx.showSnackbar('Saved new user')
    }).catch(error => {
      if (error.response) {
        snackbarCtx.showSnackbar(error.response.data.error)
        console.log('failed to save user', error)
      } else {
          snackbarCtx.showSnackbar("Error occured, please refresh and try again")
      }
    })
  }

  
  const handleFacilitySelection = (e, v) => {
    setFacilitySelected(v)
    setValue("area_code", v.area_code ? v.area_code : '')
    clearErrors('area_code')
    setValue("phone_number", v.phone_number ? v.phone_number : '')
    clearErrors('phone_number')
    setValue("street", v.street ? v.street : '')
    clearErrors('street')
    setValue("unit", v.unit ? v.unit : '')
    clearErrors('unit')
    setValue("city", v.city ? v.city : '')
    clearErrors('city')
    setValue("zip", v.zip ? v.zip : '')
    clearErrors('zip')
    setValue("state_id",  v.state_id ? states.find((state) => state.id === v.state_id)  : '')
    clearErrors('state_id')
    setValue("county_id",  v.county_id ? counties.find((county) => county.id === v.county_id) : '')
    clearErrors('county_id')

  }
  // console.log(errors)

  if (open) {
    return (
      <div>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Card variant="outlined" style={{ margin: '0px auto', display: 'flex', height: '100%', flexWrap: 'wrap', justifyContent: 'center' }} >
              <CardHeader title='Create New User Account' style={{ textAlign: 'center', padding: '10px' }}></CardHeader>
              <form onSubmit={handleSubmit(doHandleSubmit)}>
              <CardContent>
                <Grid container className='pt-3' spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Autocomplete 
                        options={facilities} 
                        freeSolo
                        data-testid="autocomplete"
                        onChange={handleFacilitySelection}
                        getOptionLabel={o => o.name}
                        renderInput={(params) => (
                          <TextField 
                            {...params} 
                            {...register('facility', {
                              required: { value: true, message: 'Required' },
                              maxLength: { value: 100, message: 'Must be less than 100 characters' }
                            })}
                            error={!!errors.facility} helperText={errors.facility && errors.facility.message}
                            placeholder="Select your facility or create a new one" 
                            multiline label="Facility Name *" variant='standard'
                            inputProps={{...params.inputProps, "data-testid": "facility"}}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      disabled={!!facilitySelected && !!facilitySelected.street}  
                      InputProps={{ readOnly: !!facilitySelected && !!facilitySelected.street}}  
                      InputLabelProps={{ shrink: watch('street') !== '' }}  
                      label="Street *" variant="standard" className="w-100"              
                      {...register(
                        'street', {
                          required: { value: true, message: 'Required' },
                        }
                      )}
                      inputProps={{"data-testid": "street"}}
                      error={!!errors.street }
                      helperText={ errors.street && errors.street.message }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      disabled={!!facilitySelected && !!facilitySelected.unit}  
                      InputProps={{ readOnly: !!facilitySelected && !!facilitySelected.unit}}   
                      label="Unit" variant="standard" className="w-100"              
                      {...register('unit')}
                      inputProps={{"data-testid": "unit"}}
                      InputLabelProps={{ shrink: watch('unit') !== '' }} 
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      disabled={!!facilitySelected && !!facilitySelected.city}  
                      InputProps={{ readOnly: !!facilitySelected && !!facilitySelected.city}}   
                      label="City *" variant="standard" className="w-100"                 
                      {...register(
                        'city', {
                          required: { value: true, message: 'Required' },
                        }
                      )}
                      InputLabelProps={{ shrink: watch('city') !== '' }} 
                      inputProps={{"data-testid": "city"}}
                      error={!!errors.city }
                      helperText={ errors.city && errors.city.message }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <RHFMultiSelect
                      name="state_id" fullWidth 
                      register={() => ({...register('state_id', {
                        required: { value: true, message: 'Required' }
                      })})}
                      disabled={!!facilitySelected && !!facilitySelected.state_id}  
                      readOnly={!!facilitySelected && !!facilitySelected.state_id}
                      control={control} 
                      label="State *"
                      options={states}
                      getOptionLabel={o => o.code || ''}
                      getOptionSelected={o => o.code || ''}
                      error={!!errors.state_id} 
                      helperText={errors.state_id && errors.state_id.message}
                      objGOS={true}
                      inputProps={{"data-testid": "state_id"}}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <RHFMultiSelect
                      name="county_id" fullWidth 
                      disabled={!!facilitySelected && !!facilitySelected.county_id}  
                      readOnly={!!facilitySelected && !!facilitySelected.county_id}
                      register={() => ({...register('county_id', {
                        required: { value: true, message: 'Required' }
                      })})}
                      label="County *" 
                      getOptionLabel={o => o.name || ''}
                      getOptionSelected={o => o.name || ''}
                      control={control}
                      options={counties} 
                      variant="standard"
                      error={!!errors.county_id} 
                      helperText={errors.county_id && errors.county_id.message}
                      objGOS={true}
                      inputProps={{"data-testid": "county_id"}}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="Zip *" variant="standard"  className="w-100"   
                      disabled={!!facilitySelected && !!facilitySelected.zip} 
                      InputProps={{ readOnly: !!facilitySelected && !!facilitySelected.zip}} 
                      {...register(
                        'zip', {
                          required: { value: true, message: 'Required' },
                          pattern: { value: /^[0-9]{5}(-[0-9]{4})?$/, message: 'Zip Code 5 digits or 5 digits - 4 digits formats' },
                        })
                      }
                      InputLabelProps={{ shrink: watch('zip') !== '' }} 
                      inputProps={{"data-testid": "zip"}}
                      error={!!errors.zip }
                      helperText={ errors.zip && errors.zip.message }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField 
                      className="w-100"
                      label="Area Code *" variant="standard"            
                      {...register (
                        'area_code', 
                        { 
                          required: { value: true, message: 'Required' },
                          pattern: { value: /^\d\d\d$/, message: 'Must be three digits' }
                        }
                      )}
                      disabled={!!facilitySelected && !!facilitySelected.area_code}  
                      InputProps={{ readOnly: !!facilitySelected && !!facilitySelected.area_code}}  
                      InputLabelProps={{ shrink: watch('area_code') !== '' }} 
                      inputProps={{"data-testid": "area_code"}}
                      error={!!errors.area_code }
                      helperText={ errors.area_code && errors.area_code.message }
                    />
                  </Grid>
                  <Grid item xs={2}>
                      <TextField
                        className="w-100"
                        label="Phone Number *" variant="standard"
                        {...register(
                          'phone_number', {
                            required: { value: true, message: 'Required' },
                            pattern: { value: /^\d{7}$/, message: 'Must be seven digits' },
                          }
                        )}
                        disabled={!!facilitySelected && !!facilitySelected.phone_number}  
                        InputProps={{ readOnly: !!facilitySelected && !!facilitySelected.phone_number}}   
                        InputLabelProps={{ shrink: watch('phone_number') !== ''}} 
                        inputProps={{"data-testid": "phone_number"}}
                        error={!!errors.phone_number }
                        helperText={ errors.phone_number && errors.phone_number.message }
                      />
                  </Grid>
                </Grid>
                <Grid container className='pt-3' spacing={2}>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField label="Last Name*" variant='standard'
                        {...register(
                          'last_name', {
                            required: { value: true, message: 'Required' },
                            maxLength: { value: 50, message: 'Must be less than 50 characters' }
                          }
                        )}
                        error={!!errors.last_name }
                        helperText={ errors.last_name && errors.last_name.message }
                        inputProps={{"data-testid": "last_name"}}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField label="First Name*" variant='standard'
                          {...register(
                            'first_name', {
                              required: { value: true, message: 'Required' },
                              maxLength: { value: 50, message: 'Must be less than 50 characters' }
                            }
                          )}
                          error={!!errors.first_name }
                          helperText={ errors.first_name && errors.first_name.message }
                          inputProps={{"data-testid": "first_name"}}
                        />
                      </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField label="Email Address*" variant='standard'
                        {...register(
                          'email', {
                            required: { value: true, message: 'Required' },
                            pattern: { value: /.+@.+\..+/, message: 'E-mail address must be valid' }
                          }
                        )}
                        error={!!errors.email}
                        helperText={ errors.email && errors.email.message }
                        inputProps={{"data-testid": "email"}}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="Password*" type="password" autoComplete="new-password"
                        {...register(
                          'password', {
                            required: { value: true, message: 'Required' },
                            pattern: { value: /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/, message: 'Password must meet requirements below' },
                            minLength: { value: 8, message: 'Password must meet requirements below' }
                          }
                        )}
                        error={!!errors.password } variant='standard'
                        helperText={ errors.password && errors.password.message }
                        inputProps={{"data-testid": "password"}}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                      <FormControl fullWidth>
                        <TextField
                          label="Confirm Password *" type="password"
                          autoComplete="new-password" variant='standard'
                          {...register(
                            'confirm_password', {
                              required: { value: true, message: 'Required' },
                              pattern: { value: /^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/, message: 'Password must meet requirements below' },
                              minLength: { value: 8, message: 'Password must meet requirements below' },
                              validate: {
                                passwordsMatch: v => {
                                  const { password } = getValues()
                                  return v === password || 'Passwords must match'
                                }
                              }
                            }
                          )}
                          error={!!errors.confirm_password }
                          helperText={ errors.confirm_password && errors.confirm_password.message }
                          inputProps={{"data-testid": "confirm_password"}}
                        />
                      </FormControl>
                  </Grid>
                </Grid>
                <Grid container className='pt-3' spacing={2}>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={8} style={{ paddingTop: '10px' }}>
                      <Typography component={'div'} variant="subtitle2">Password must be minimum 8 characters, contain a letter, number and special character [*,%,$]</Typography>
                  </Grid>
                  <Grid item xs={2}></Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <div style={{ flex: '1 0 0' }} />
                <Button color="primary" variant="outlined" onClick={() => setOpen(false)}>CANCEL</Button>
                <Button color="secondary" variant="outlined" data-testid="save" type="submit">SIGN UP</Button>
                <div style={{ flex: '1 0 0' }} />
              </CardActions>
              </form>
            </Card>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    )
  }
  return null
}
