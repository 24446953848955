import { SET_USERS, SET_USER_COUNT, SET_USER_PAGE, SET_USER_SEARCH_PARAMS } from './userManagement.types'

const INITIAL_STATE = {
  limit: 25,
  count: 0,
  page: 0,
  params: {
    lastName: '',
    firstName: '',
    email: '',
    status: []
  },
  users: []
}

const users = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER_PAGE:
      return {
        ...state, page: action.page
      }
    case SET_USER_COUNT:
      return {
        ...state, count: action.count
      }
    case SET_USER_SEARCH_PARAMS:
      return {
        ...state, params: action.params
      }
    case SET_USERS:
      return {
        ...state, users: action.users
      }
    default:
      return state
  }
}

export default users
