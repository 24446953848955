import {
    Button,
    Card,
    CardContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
  } from "@mui/material";
  import React, { useContext, useState, useEffect } from "react";
  import { useForm } from "react-hook-form";
  import api from "../../config/axios.config";
  import SnackbarContext from "../../lib/context/SnackbarContext";
  
  export default function FacilityConditions(props) {
    const snackbarCtx = useContext(SnackbarContext);
    const [conditions, setConditions] = useState([]);
    const [condition, setCondition] = useState("");
  
    const {
      register,
      handleSubmit,
      getValues,
      formState: { errors },
    } = useForm({ mode: "onBlur" });
  
    useEffect(() => {
      api.get("/api/conditions/")
        .then((response) => {
          setConditions(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    }, [setConditions]);
  
    const addCondition = () => {
      //testing to make sure not empty string
      if(condition){
        const params = new URLSearchParams()
        params.append('facility_id', props?.facility?.id);
        params.append('condition_id', condition);
        api.post('api/facility_condition', {}, { params })
          .then(
              (res) => {
                  props.getFacilities();
                  snackbarCtx.showSnackbar(res.data.message)
                  setCondition('')
              }
          ).catch(
            snackbarCtx.showSnackbar("Error adding Condition")
            //any other error handling?
          )
      }
    };
  
    const changeCondition = (event) => {
      setCondition(event.target.value);
    };
  
    return (
      <>
        <Card className="mt-3" variant="outlined">
          <CardContent>
            <p className="m-0 card-header">Facility Conditions</p>
            <div className="pt-3">
              {props?.facility?.conditions?.map((condition, index) => {
                return (
                  <div key={index}>
                    <p>{condition?.name}</p>
                  </div>
                );
              })}
            </div>
            <div className="pt-3">
              <p className="m-0 card-header">Add new condition</p>
              <div className="pt-3 d-flex align-center">
              <FormControl sx={{ minWidth: 300 }}>
                <InputLabel>Condition</InputLabel>
                <Select
                  fullWidth
                  className="ml-3"
                  variant="standard"
                  value={condition}
                  onChange={changeCondition}
                  label="Condition"
                  required
                >
                  {conditions?.map((condition, index) => {
                    return (
                      <MenuItem key={index} value={condition.id}>
                        {condition.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <div className="ml-5">
              <Button
                color="primary"
                variant="contained"
                onClick={addCondition}
              >
                Add Condition
              </Button>
              </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </>
    );
  }
  