import React, { useContext, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import UserFacilities from "./UserFacilities";
import { pathname, useMuiRegister } from "../../lib/misc";
import SnackbarContext from "../../lib/context/SnackbarContext";
import LoadingButton from "@mui/lab/LoadingButton";
import api from "../../config/axios.config";
import UserContext from '../../lib/context/UserContext';
import { PRIVILEGES } from "../../constants/roles.constants";
import { checkPrivilege } from "../../helpers/privilegesHelper";

export default function ShowUser() {
  const snackbarCtx = useContext(SnackbarContext);
  const params = useParams();
  const [readOnly, setReadOnly] = useState(false);
  const [user, setUser] = useState({});
  const [status, setStatus] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [adminEmail, setAdminEmail]  = useState(false);
  const [showEmailCheckbox, setEmailCheckbox] = useState(false);
  const navigate = useNavigate();
  const statuses = ["ACTIVE", "PENDING", "LOCKED", "DISABLED"];
  const { currentUser } = React.useContext(UserContext);

  const userSchema = yup.object().shape({
    username: yup
      .string()
      .email()
      .required("Required")
      .default("")
      .max(50, "Must be less than 50 characters"),
    first_name: yup
      .string()
      .required("Required")
      .default("")
      .max(50, "Must be less than 50 characters"),
    last_name: yup
      .string()
      .required("Required")
      .default("")
      .max(50, "Must be less than 50 characters"),
    status: yup
      .string()
      .typeError("Please select a status")
      .required("Required"),
    admin_email: yup
      .boolean()

  });

  const formOpts = { mode: "all" };
  formOpts.resolver = yupResolver(userSchema);
  const {
    setValue,
    watch,
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm(formOpts);

  React.useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    api.get(`/api/users/${params.user}`)
    .then((res) => {
      if (res.data.data) {
        setReadOnly(true);
        const _user = res.data.data
        setUserDefaults(_user);
        if (checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITIES) && checkPrivilege(_user, PRIVILEGES.MANAGE_FACILITIES)) {
          setEmailCheckbox(true)
        }
      } else {
        navigate(pathname(true) + "users");
      }
    })
    .catch((err) => {
      console.log(err)
    });
};

  const editUser = () => {
    setReadOnly(false);
  };

  const handleStatus = (event) => {
    setStatus(event.target.value);
    setValue("status", event.target.value);
  };

  const cancelEdit = () => {
    setReadOnly(true);
    setUserDefaults(user);
  };

  const setUserDefaults = (user) => {
    let defaultValues = {
      username: user.username ? user.username : "",
      first_name: user.first_name ? user.first_name : "",
      last_name: user.last_name ? user.last_name : "",
      status: user.status ? user.status : "",
      admin_email: user.admin_email ? user.admin_email : false
    };
    setStatus(user.status);
    setAdminEmail(user.admin_email)
    setUser(user);
    reset(defaultValues);
  };

  const save = () => {
    let parameters = getValues();
    console.log(parameters);
    parameters["id"] = params.user;
    parameters["admin_email"] = adminEmail
    setSaveLoading(true);
    api
      .patch(`/api/users/${params.user}`, parameters)
      .then((res) => {
        setUserDefaults(res.data.user);
        snackbarCtx.showSnackbar("User Updated");
        setSaveLoading(false);
        setReadOnly(true);
        if(res.data?.user?.status === 'DISABLED') {
          navigate('/users')
        }
      })
      .catch((err) => {
        setUserDefaults(user);
        snackbarCtx.showSnackbar("Error updating User");
        setSaveLoading(false);
        setReadOnly(true);
      });
  };

  const childProps = {
    readOnly,
    setValue,
    getValues,
    errors,
    register: useMuiRegister(register),
    control,
    watch,
    user,
    getUser,
  };

  return (
    <>
      <form onSubmit={handleSubmit(save)}>
        <Card className="mt-3" variant="outlined">
          <CardContent>
            <FormGroup>
              <FormControl>
                <div className="d-flex justify-between">
                  <p className="m-0 card-header">USER INFORMATION</p>
                  {readOnly && (
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={editUser}
                    >
                      Edit User
                    </Button>
                  )}
                  {!readOnly && (
                    <div>
                      <Button
                        color="secondary"
                        variant="contained"
                        type="submit"
                        onClick={cancelEdit}
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        className="ml-3"
                        color="secondary"
                        variant="contained"
                        type="submit"
                        loading={saveLoading}
                      >
                        Save User
                      </LoadingButton>
                    </div>
                  )}
                </div>
                <Grid className="pt-3" container spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      className="w-100"
                      name="username"
                      inputProps={{ readOnly: true }}
                      label="Email *"
                      variant="standard"
                      disabled
                      InputLabelProps={{ shrink: watch("username") !== "" }}
                      {...register("username")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      className="w-100"
                      name="last_name"
                      inputProps={{ readOnly: readOnly }}
                      label="Last Name *"
                      variant="standard"
                      error={!!errors.last_name}
                      helperText={errors?.last_name?.message}
                      InputLabelProps={{ shrink: watch("last_name") !== "" }}
                      {...register("last_name")}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      className="w-100"
                      name="first_name"
                      inputProps={{ readOnly: readOnly }}
                      label="First Name *"
                      error={!!errors.first_name}
                      helperText={errors?.first_name?.message}
                      variant="standard"
                      InputLabelProps={{ shrink: watch("first_name") !== "" }}
                      {...register("first_name")}
                    />
                  </Grid>
                  <Grid className="pt-3" item xs={3}>
                    <FormControl fullWidth>
                      <InputLabel>Status *</InputLabel>
                      <Select
                        variant="standard"
                        value={status}
                        readOnly={readOnly}
                        onChange={handleStatus}
                        label="Status *"
                      >
                        {statuses.map((status, index) => {
                          return (
                            <MenuItem key={index} value={status}>
                              {status}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  { showEmailCheckbox ? (
                    <Grid className="pt-3" item xs={3}>
                    <FormControlLabel
                      control={
                        <Checkbox name="admin_email" checked={adminEmail} onChange={(e) => setAdminEmail(e.target.checked)} disabled={readOnly} />
                      }
                      label="Receive Onboarding Emails"
                    />
                  </Grid>
                  ) : null}
                </Grid>
              </FormControl>
            </FormGroup>
          </CardContent>
        </Card>
      </form>
      {checkPrivilege(currentUser, PRIVILEGES.MANAGE_FACILITIES) ? <UserFacilities {...childProps} /> : null}
    </>
  );
}
