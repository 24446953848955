import React from 'react'

const sources = {
  clinicians: [],
  counties: [],
  facilities: [],
  facility_visit_types: [],
  conditions: [],
  telephone_types: [],
  genders: [],
  ethnicities: [],
  races: [],
  sources_loaded: false,
  roles: []
}

export default React.createContext({
  setClinicians: (c) => {},
  setCounties: (c) => {},
  setConditions: (c) => {},
  setFacilities: (c) => {},
  setTelephoneTypes: (c) => {},
  setGenders: (c) => {},
  setSources: (c) => {},
  reset: () => {},
  setRoles: (c) => {},
  ...sources
})
