import React, { useContext, useEffect } from 'react'
import { Button, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField } from '@mui/material'
import axios from 'axios'
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { pathname } from '../../lib/misc'
import SnackbarContext from '../../lib/context/SnackbarContext';
import UserContext from '../../lib/context/UserContext';
import api from '../../config/axios.config';

export default function ConditionManagement () {

  const [conditions, setConditions] = useState([])
  const [conditionName, setConditionName] = useState('')
  const [conditionCode, setConditionCode] = useState('')
  const [dialog, setDialog] = useState(false)
  const snackbarCtx = useContext(SnackbarContext);
  const { currentUser } = React.useContext(UserContext)

  const linkToCase = (params) => {
    return (
      <Link to={pathname(true) + 'condition/' + params.id}>{ params.name}</Link>
    )
  }

  const columns = [
    { field: 'name', headerName: 'Condition Name', renderCell: linkToCase, width: '50%', sortable: false },
    { field: 'code', headerName: 'Code',  width: '50%', sortable: false },
]

  useEffect(() => {
    getConditions();
  }, [])

  const handleClickOpen = (facility) => {
    setDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
    resetInputs();
  };

  const resetInputs = () => {
    setConditionName('');
    setConditionCode('');
  }

  const checkDisabled = () => {
    let disabled = true;
    if(conditionName.length > 0 && conditionCode.length > 0){
      disabled = false;
    }
    return disabled;
  }

  const createCondition = () => {
    const body = {
      condition: {
        name: conditionName,
        code: conditionCode,
        user_id: currentUser.id
      }
    }
    api.post("/api/conditions", body)
      .then((resp) => {
        handleClose();
        snackbarCtx.showSnackbar(resp.data?.message);
        getConditions();
      })
      .catch((err) => {
        handleClose();
        snackbarCtx.showSnackbar(err.message);
      })
  }

  const getConditions = () => {
    api.get('/api/conditions')
    .then((resp) => {
      setConditions(resp.data)
    })
  }

  return (
      <>
      <p className='main-header m-0 text-center pb-3'>Condition Management</p>
        <div className='d-flex justify-end py-3'>
            <Button color="secondary"
                      variant="contained"
                      onClick={() => setDialog(true)}
                    >Create Condition</Button>
            <Dialog
                    open={dialog}
                    onClose={handleClose}
                    fullWidth
                    maxWidth="sm"
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Create Condition"}
                    </DialogTitle>
                    <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Condition Name"
                        type="text"
                        fullWidth
                        value={conditionName}
                        onChange={() => setConditionName(event.target.value)}
                        variant="standard"
                      />
                      <TextField
                        margin="dense"
                        id="code"
                        label="Condition Code"
                        type="number"
                        value={conditionCode}
                        onChange={() => setConditionCode(event.target.value)}
                        variant="standard"
                      />
                    </DialogContent>
                    <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={checkDisabled()} onClick={createCondition}>Create</Button>
        </DialogActions>
                  </Dialog>
        </div>
        <TableContainer>
            <Table size="medium" stickyHeader>
            <colgroup>
                {columns.map((column, index) => (
                <col key={index} width={column.width} />
                ))}
            </colgroup>
            <TableHead className="tableHeader">
                <TableRow>
                {columns.map((column, index) => (
                    <TableCell key={index} align="left">
                    {column.headerName}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {conditions.map((condition, index) => {
                return (
                    <TableRow key={index} style={{height: 10}}>
                    {columns.map((column, index) => {
                        return column?.renderCell ? (
                        <TableCell
                            align="left"
                            key={index}
                        >
                            {column.renderCell(condition)}
                        </TableCell>
                        ) : (
                        <TableCell
                            align="left"
                            key={index}
                        >
                            {condition[column.field]}
                        </TableCell>
                        );
                    })}
                    </TableRow>
                );
                })}
            </TableBody>
            </Table>
        </TableContainer>
      </>
  )
}
