import { SET_COUNT, SET_NOTIFICATIONS, SET_PAGE, SET_SEARCH_PARAMS } from './notification.types'

export const setPage = (page) => {
  return {
    type: SET_PAGE,
    page: page
  }
}

export const setCount = (count) => {
  return {
    type: SET_COUNT,
    count: count
  }
}

export const setSearchParams = (params) => {
  return {
    type: SET_SEARCH_PARAMS,
    params: params
  }
}

export const setNotifications = (notifications) => {
  return {
    type: SET_NOTIFICATIONS,
    notifications: notifications
  }
}
