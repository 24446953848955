import moment from 'moment'
import { SET_BULK_UPLOADS, SET_BULK_UPLOAD_COUNT, SET_BULK_UPLOAD_PAGE, SET_BULK_UPLOAD_SEARCH_PARAMS } from './bulkupload.types'

const INITIAL_STATE = {
  limit: 25,
  count: 0,
  page: 0,
  params: {
    fromDate: moment().subtract(1, 'months').format('YYYY-MM-DD'),
    toDate: moment().format('YYYY-MM-DD'),
    selectedFacilities: []
  },
  uploads: []
}

const bulkUploads = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BULK_UPLOAD_PAGE:
      return {
        ...state, page: action.page
      }
    case SET_BULK_UPLOAD_COUNT:
      return {
        ...state, count: action.count
      }
    case SET_BULK_UPLOAD_SEARCH_PARAMS:
      return {
        ...state, params: action.params
      }
    case SET_BULK_UPLOADS:
      return {
        ...state, uploads: action.uploads
      }
    default:
      return state
  }
}

export default bulkUploads
