import { SET_PATIENTS_SEARCHED, SET_PATIENT_ID, SET_PATIENT_SEARCH_RESULTS, SET_PATIENT_SELECTED } from "./patientSearch.types"

export const setPatientSearchResults = (patients) => {
    return {
      type: SET_PATIENT_SEARCH_RESULTS,
      patients: patients
    }
  }

export const setPatientSearched = (searched) => {
  return {
    type: SET_PATIENTS_SEARCHED,
    searched: searched
  }
}

export const setPatientSelected = (selected) => {
  return {
    type: SET_PATIENT_SELECTED,
    selected: selected
  }
}

export const setPatientId = (id) => {
  return {
    type: SET_PATIENT_ID,
    id: id
  }
}